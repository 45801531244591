import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useAuth from "../../context/useAuth";

const DescuentoEnBusqueda = ({ articulo }) => {
  const [tipoDescuento, setTipoDescuento] = useState("");
  const { token } = useAuth();

  useEffect(() => {
    const getDescuento = async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await fetch(
        `${process.env.REACT_APP_Url}common/pos/descuento/${articulo.codArticulo}`,
        options
      );
      const response = await data.json();
      setTipoDescuento(response);
    };
    // if (
    //   infoArticulo.codArticulo !== undefined &&
    //   infoArticulo.codArticulo !== 0
    // ) {
    getDescuento();
    // dependencies: infoArticulo.codArticulo, infoArticulo.cantidad}
  }, []);

  return (
    <Container justifyContent="center">
      <Typography variant="h4" sx={{ my: 4 }} align="center">
        Descuento por artículo
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={1} justifyContent="center">
            {tipoDescuento?.map((tipoD, index) => (
              <Grid item xs={3} key={index}>
                <Button
                  // onClick={
                  //   () => {
                  //   valDescAut(tipoD.optTitleColumn);
                  // }}
                  variant="contained"
                  key={index}
                >
                  {tipoD.optLabelColumn}
                </Button>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      <Card justifyContent="center">
        <CardContent justifyContent="center">
          <div>
            <Typography variant="h4" align="center">
              Tipo:
            </Typography>
          </div>
          <Container justifyContent="center">
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={9} md={9} align="center">
                <FormControl>
                  <RadioGroup row>
                    <FormControlLabel
                      id="monto"
                      name="monto"
                      // onChange={() => setTipoCantidad(true)}
                      label="Monto"
                      control={
                        <Radio
                          // checked={radioSelected === "monto"}
                          // onChange={(e) => checkRadio(e)}
                          value="monto"
                        />
                      }
                    />

                    <FormControlLabel
                      id="porcentual"
                      name="porcentual"
                      // onChange={() => {
                      //   setPorcentual(true);
                      // }}
                      label="Porcentual"
                      control={
                        <Radio
                          // checked={radioSelected === "porcentual"}
                          // onChange={(e) => checkRadio(e)}
                          value="porcentual"
                        />
                      }
                    />
                    <FormControlLabel
                      id="cantidad"
                      name="cantidad"
                      // onChange={() => setTipoCantidad(true)}
                      label="Cantidad"
                      control={
                        <Radio
                          // checked={radioSelected === "cantidad"}
                          // onChange={(e) => checkRadio(e)}
                          value="cantidad"
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  label="Cantidad"
                  sx={{ mr: 4 }}
                  // onChange={(e) => handleCantidad(e)}
                ></TextField>

                <TextField
                  label="Valor"
                  // onChange={(e) => setValor(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>
          </Container>
          <CardActions justifyContent="center">
            <Button
              style={{ margin: "0 auto", display: "flex", marginTop: "2rem" }}
            >
              Aplicar
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DescuentoEnBusqueda;
