import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import getUser from "../ayudantes/getUser";
import refrescarToken from "../ayudantes/RefrescarToken";
import { BroadcastChannel } from "broadcast-channel";

const UserContext = createContext();

const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  const [user, setUser] = useState(null);
  const [olvidoContrasena, setOlvidoContrasena] = useState(false);
  const [token, setToken] = useState(cookies.token);
  const [refreshToken, setRefreshToken] = useState(cookies.refresh_token);
  const [error] = useState(false);
  const [closeTab, setCloseTab] = useState(false);
  const [cajero, setCajero] = useState();
  const [renovarSesion, setRenovarSesion] = useState(false);
  const [cajaDefault, setCajaDefault] = useState();
  const [paramFacturaPos, setparamFacturaPos] = useState();
  const [familia, setFamilia] = useState(null);
  const [vencimiento, setVencimiento] = useState(cookies.vencimiento);
  const [closeAllWindows, setCloseAllWindows] = useState(false);
  const handleClose = () => setRenovarSesion(false);
  const channel = new BroadcastChannel("foobar");

  //solo para tests
  const [testVencimiento, setTestVencimiento] = useState(180);

  const login = async (userData) => {
    const response = await getUser(userData);
    setCookie("refresh_token", await response.refresh_token);
    setCookie("token", await response.access_token);
    setCookie("vencimiento", await response.expires_in);
    setToken(response.access_token);
  };

  useEffect(() => {
    const getOwn = async () => {
      let myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const current = await fetch(
        `${process.env.REACT_APP_Url}cajero/own`,
        requestOptions
      );
      if (current.status === 401) {
        logout();
      }
      if (!current.ok) {
        console.error(`Ha habido un error ${current.status}`);
      }
      const currentData = await current.json();
      setCajero(currentData);
    };

    token && getOwn();
  }, [token]);
  //Caja Default
  useEffect(() => {
    const getCajaDefault = async () => {
      let myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const current = await fetch(
        `${process.env.REACT_APP_Url}common/pos/default`,
        requestOptions
      );
      if (current.status === 401) {
        logout();
      }
      const currentData = await current.json();
      setCajaDefault(currentData);
    };
    token && getCajaDefault();
  }, [token]);
  //familia para dashboard

  useEffect(() => {
    let mounted = true;
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    token &&
      mounted &&
      fetch(`${process.env.REACT_APP_Url}common/pos/familiaArticulo`, options)
        .then((data) => data.json())
        .then((res) => {
          setFamilia(res);
        });
    return () => (mounted = false);
  }, [token]);

  //param facturaPos
  useEffect(() => {
    const getFacturaPos = async () => {
      let myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const current = await fetch(
        `${process.env.REACT_APP_Url}common/pos/paramFacturaPos`,
        requestOptions
      );
      const currentData = await current.json();
      setparamFacturaPos(currentData);
    };
    token && getFacturaPos();
  }, [token]);
  //Logica del logout
  const logout = () => {
    const requestOptions = {
      method: "POST",
    };

    fetch(`${process.env.REACT_APP_Url}oauth/logout`, requestOptions);
    setCloseAllWindows(true);
    removeCookie("token");
    removeCookie("refresh_token");
    removeCookie("vencimiento");
    setUser(null);
    setToken(null);
    setRefreshToken(null);
    setRenovarSesion(false);
    setCloseTab(true);
    channel.postMessage("close");
  };

  const refresh = async () => {
    console.log("refresh!");
    refrescarToken(
      refreshToken,
      setToken,
      setCookie,
      setRefreshToken,
      setVencimiento
    );
    window.location.reload();
  };

  const data = {
    user,
    setUser,
    cajero,
    cajaDefault,
    paramFacturaPos,
    familia,
    closeTab,
    closeAllWindows,
    error,
    login,
    logout,
    setToken,
    setRefreshToken,
    token,
    refreshToken,
    refresh,
    renovarSesion,
    setRenovarSesion,
    olvidoContrasena,
    setCloseAllWindows,
    setOlvidoContrasena,
    handleClose,
    vencimiento,
    testVencimiento,
    setTestVencimiento,
    setCloseTab,
  };

  return <UserContext.Provider value={data}> {children} </UserContext.Provider>;
};

export { UserContext, AuthProvider };
