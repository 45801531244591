import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Switch, Typography } from "@mui/material";
import useAuth from "../../context/useAuth";
import { toast } from "react-toastify";

const PrecioDinamico = ({ setSave, save, cajeroSelect, show, setShow }) => {
  const [field, setField] = useState([]);
  const [check, setChecked] = useState(true);
  const { familia, token, logout } = useAuth();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const notifyFamiliasActualizadas = () =>
    toast.success("El cajero fue actualizado");
  const notifyFamiliasNoActualizadas = () =>
    toast.error("No se pudo actualizar el cajero");
  const notifyFamiliasNoExisten = () =>
    toast.error("No se pudo consultar el cajero");
  useEffect(() => {
    const toUpdateFamilias = async () => {
      const current = await updateFamilias(check, field, cajeroSelect);
      if (current.status === 201) {
        notifyFamiliasActualizadas();
        setSave(false);
        setShow(false);
      } else if (current.status === 404) {
        notifyFamiliasNoActualizadas();
        setSave(false);
        setShow(false);
      } else if (current.status === 401) {
        notifyFamiliasNoActualizadas();
        logout();
      }
    };
    if (save === true) {
      toUpdateFamilias();
    }
  }, [save]);

  const updateFamilias = async (check, field, cajeroSelect) => {
    let data = {
      isDinamicPriceEnabled: check,
      listadoFamilias: field,
      idCajero: cajeroSelect,
    };
    let options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    return fetch(
      `${process.env.REACT_APP_Url}precioDinamico/setFamilia`,
      options
    );
  };
  useEffect(() => {
    const toGetFamilias = async () => {
      const current = await getFamilias(cajeroSelect);
      if (current.status === 401) {
        logout();
      } else if (current.status === 404) {
        notifyFamiliasNoExisten();
      } else if (current.status === 200) {
        const currentData = await current.json();
        if (currentData?.listadoFamilias.length > 0) {
          setField(currentData?.listadoFamilias);
        }
      }
    };
    if (show === true) {
      toGetFamilias();
    }
  }, [show]);
  const getFamilias = async (cajeroSelect) => {
    let options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_Url}precioDinamico/${cajeroSelect}`,
      options
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Container>
        <Row style={{ height: "80%" }}>
          <Col>
            <Card style={{ minwidth: "70%", height: "80%" }}>
              <Form.Group as={Col} controlId="my_multiselect_field">
                <Form.Label>Familias</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={field}
                  onChange={(e) =>
                    setField(
                      [].slice
                        .call(e.target.selectedOptions)
                        .map((item) => item.value)
                    )
                  }
                >
                  {familia?.map((articulo, index) => (
                    <option key={index} value={articulo.codFlia}>
                      {articulo.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{ minwidth: "30%" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Habilitar el precio dinamico
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Al habilitar el precio dinamico, el cajero podra actualizar el
                precio en las familias seleccionadas previamente.
              </Typography>

              <Switch onChange={handleChange} defaultChecked />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PrecioDinamico;
