const initialValue = {
  active: "",
  display: false,
};

const reducer = (state, { type }) => {
  switch (type) {
    case "show":
      return { ...state, active: "is-active", display: true };
    case "hide":
      return initialValue;
    default:
      console.log('A wrong value was recived. Choose "hide" or "show"');
  }
};

export { initialValue, reducer };
