import React, { useReducer } from "react";
import logo from "../logoSIGMA250.png";
import avatar from "../default-avatar.png";
import { Link } from "react-router-dom";
import { initialValue, reducer } from "../reducers/reducer";
import useAuth from "../context/useAuth";

const NavBar = ({ admin, cajero, logout, setListado, getListaDeCajeros }) => {
  const [show, dispatch] = useReducer(reducer, initialValue);
  return (
    <div
      className="container is-flex is-justify-content-space-around"
      style={{ minWidth: "100vw" }}
    >
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        style={{ borderBottom: "0.5px solid #dcdcdc", width: "90%" }}
      >
        <div className="navbar-brand ">
          <a
            className="navbar-item"
            href="https://ptfront.dev.getsigmacare.com/"
          >
            <img
              src={logo}
              alt="Logo sigma"
              width="150"
              style={{ minHeight: "2.5rem" }}
            />
          </a>

          <button
            onClick={() =>
              dispatch({ type: show.display === true ? "hide" : "show" })
            }
            className={`navbar-burger ${show.active} `}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <div className="navbar-item">
              <Link target="_blank" to={"/pos"}>
                Ir al POS
              </Link>
            </div>
            {admin && (
              <a
                className="navbar-item"
                onClick={() => {
                  setListado(true);
                  getListaDeCajeros();
                }}
              >
                Listado de cajeros
              </a>
            )}
          </div>

          <div className="navbar-end">
            <div
              className="container is-flex is-justify-content-end"
              style={{ width: "50vw" }}
            >
              <div className="navbar-item">
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link">
                    <img
                      alt="Avatar"
                      className="is-rounded"
                      style={{ minHeight: 40 }}
                      width="40"
                      src={avatar}
                    />
                  </a>

                  <div
                    className="navbar-dropdown is-right"
                    style={{ minWidth: "20rem", minHeight: "10rem" }}
                  >
                    <div
                      className="container pb-4 "
                      style={{ borderBottom: "0.5px solid #dcdcdc" }}
                    >
                      <div className="columns is-flex is-align-items-center">
                        <div className="column is-one-quarter">
                          <img
                            alt="Avatar"
                            className="is-rounded "
                            style={{ minHeight: 40 }}
                            width="40"
                            height="40"
                            src={avatar}
                          />
                        </div>
                        <div className="column is-flex is-flex-direction-column is-align-content-flex-start">
                          <p className=" has-text-weight-bold is-size-5 ">
                            {cajero.nombre || ""}
                          </p>
                          <p className="has-text-grey-light">
                            {cajero.usuarioPos || ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    <a
                      className="navbar-item p-3"
                      style={{ borderBottom: "0.5px solid #dcdcdc" }}
                    >
                      Perfil
                    </a>
                    <a className="navbar-item p-3" onClick={logout}>
                      Desconectar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {show.display && (
        <div className="container is-flex is-justify-content-center">
          <div className="box">
            {" "}
            <div className="navbar-dropdown is-right">
              <div
                className="container pb-4"
                style={{ borderBottom: "0.5px solid #dcdcdc" }}
              >
                <div className="columns is-flex is-align-items-center">
                  <div className="column is-one-quarter">
                    <img
                      alt="Avatar"
                      className="is-rounded "
                      style={{ minHeight: 40 }}
                      width="40"
                      height="40"
                      src={avatar}
                    />
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-content-flex-start">
                    <p className=" has-text-weight-bold is-size-5 ">admin</p>
                    <p className="has-text-grey-light">getsigmacare</p>
                  </div>
                </div>
              </div>

              <a
                className="navbar-item p-3"
                style={{ borderBottom: "0.5px solid #dcdcdc" }}
              >
                Perfil
              </a>
              <a
                className="navbar-item p-3"
                onClick={() => {
                  setListado(true);
                  getListaDeCajeros();
                }}
              >
                Listado de cajeros
              </a>
              <a className="navbar-item p-3">Ir al POS</a>
              <a className="navbar-item p-3" onClick={logout}>
                Desconectar
              </a>
            </div>
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default NavBar;
