import React, { useState, useEffect } from "react";
import useAuth from "../../context/useAuth";

const Tipo = ({ setSubRefType }) => {
  const { token } = useAuth();
  const [opciones, setOpciones] = useState([]);
  const [seleccion, setSeleccion] = useState("CHEQUE DEVUELTO");
  const handleChange = (e) => setSeleccion(e.target.value);
  useEffect(() => {
    let mounted = true;
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    mounted &&
      fetch(
        `${process.env.REACT_APP_Url}common/pos/tipoClienteByCompania`,
        options
      )
        .then((data) => data.json())
        .then((res) => {
          setOpciones(res);
        });

    const current = opciones?.filter(
      (opcion) => opcion.descripcion === seleccion
    );
    setSubRefType(current[0]?.id);
    return () => (mounted = false);
  }, [seleccion]);
  return (
    <>
      <label>Tipo</label>
      <select
        className="form-select"
        aria-label="Select example"
        value={seleccion}
        onChange={(e) => handleChange(e)}
      >
        <option>Seleccione</option>
        {opciones?.map((client, index) => (
          <option
            className="is-selected"
            value={client.descripcion}
            key={index}
          >
            {client.descripcion}
          </option>
        ))}
      </select>
    </>
  );
};

export default Tipo;
