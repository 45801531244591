import React, { useState } from "react";
import useRegistrarCajero from "../../hooks/useRegistrarCajero";
import { TableCell, TableRow, Checkbox, Input } from "@mui/material";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { TreeView, TreeItem } from "@mui/lab";
const CajerosRegistrados = ({
  cajero,
  getListaDeCajeros,
  setShow,
  setcajeroSelect,
}) => {
  const [loading, setLoading] = useState("");

  const { dispatch } = useRegistrarCajero();

  const handleClickFamilia = () => {
    setcajeroSelect(cajero.codigo);
    setShow(true);
  };

  const handleClick = () => {
    setLoading("is-loading");
    dispatch({
      type: "eliminar",
      payload: {
        idCajero: cajero.codigo,
        nombreUsuario: cajero.nombre,
        nombreCompania: cajero.nombreCompania,
        idUsuarioPos: cajero.idUsuarioPos,
      },
    });
    setTimeout(() => getListaDeCajeros(), 500);
  };
  return (
    <TableRow
      key={cajero.codigo}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell width={"5%"}>{cajero.nombre}</TableCell>
      <TableCell>{cajero.usuario}</TableCell>
      <TableCell>{cajero.nombreCompania}</TableCell>
      <TableCell>{cajero.codigo}</TableCell>
      <TableCell>{cajero.usuarioPos}</TableCell>
      <TableCell>
        {" "}
        <button
          style={{ margin: "auto", display: "flex" }}
          className={`button is-danger ${loading}`}
          onClick={() => handleClick()}
        >
          {" "}
          x{" "}
        </button>{" "}
      </TableCell>
      <TableCell>
        <button
          style={{ margin: "auto", display: "flex" }}
          className={`button is-primary is-small is-success ${loading}`}
          onClick={() => handleClickFamilia()}
        >
          <i className="fa fa-cog fa-fw fa-2x" style={{ color: "white" }}></i>
        </button>
      </TableCell>
    </TableRow>
  );
};

export default CajerosRegistrados;
