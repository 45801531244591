import React from "react";

const Footer = () => {
  return (
    <footer className="container">
      <div className="is-flex pt-4">
        <p className="has-text-grey-light">2022©</p>
        <a className="pl-2" href="https://getsigmacare.com/">
          Sigma
        </a>
      </div>
    </footer>
  );
};

export default Footer;
