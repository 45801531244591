import { useState, useEffect } from "react";

const usePaginacion = (total) => {
  const arrayPaginas = [...Array(total).keys()].map((x) => x + 1);
  const [paginas, setPaginas] = useState(
    total > 14 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] : arrayPaginas
  );
  const [botonAnterior, setBotonAnterior] = useState();
  const [botonSiguiente, setBotonSiguiente] = useState();
  const [cantidadSiguiente, setCantidadSiguiente] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setPaginas(
        total > 14
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
          : arrayPaginas
      );

      const anterior = () => {
        if (paginas.includes(1)) {
          setBotonAnterior(false);
          return;
        }
        setBotonAnterior(true);
      };
      const siguiente = () => {
        if (paginas.includes(total)) {
          setBotonSiguiente(false);
          return;
        }
        setBotonSiguiente(true);
      };
      anterior();
      siguiente();
    }
    return () => (mounted = false);
  }, [total, botonAnterior]);
  useEffect(() => {}, [paginas, cantidadSiguiente]);
  const siguiente = () => {
    const cantidadSiguiente = [];
    paginas.forEach((pagina) => {
      const result = pagina + 14;
      if (typeof result === "number" && result <= total) {
        cantidadSiguiente.push(result);
      }
    });
    if (cantidadSiguiente.includes(paginas[paginas.length - 1])) {
      setBotonSiguiente(false);
    }
    if (!cantidadSiguiente.includes(total)) {
      setBotonSiguiente(true);
    }
    if (!cantidadSiguiente.includes(1)) {
      setBotonAnterior(true);
    }
    setCantidadSiguiente(cantidadSiguiente);
    setPaginas(cantidadSiguiente);
  };

  const anterior = () => {
    const cantidadAnterior = [];
    paginas.forEach((pagina) => {
      const result = pagina - 10;
      if (typeof result === "number" && result <= total && result >= 1) {
        cantidadAnterior.push(result);
      }
    });
    if (cantidadAnterior.includes(1)) {
      setBotonAnterior(false);
    }
    if (!cantidadAnterior.includes(1)) {
      setBotonAnterior(true);
    }
    if (!cantidadAnterior.includes(total)) {
      setBotonSiguiente(true);
    }
    setPaginas(cantidadAnterior);
  };

  return [
    paginas,
    anterior,
    siguiente,
    botonAnterior,
    botonSiguiente,
    setPaginas,
  ];
};

export default usePaginacion;
