import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import useAuth from "../context/useAuth";
import Listado from "./cajeros/Index";
import jwt_decode from "jwt-decode";
import { BroadcastChannel } from "broadcast-channel";
const Dashboard = () => {
  const channel = new BroadcastChannel("foobar");
  const { token, logout, renovarSesion, setToken } = useAuth();
  const [close, setClose] = useState(false);
  channel.onmessage = (msg) => {
    setClose(true);
  };
  useEffect(() => {
    setToken(token);
  }, [token]);
  useEffect(() => {
    if (close) {
      logout();
      window.location.reload();
    }
  }, [close]);

  const [cajero, setCajero] = useState({
    nombre: "",
    usuarioPos: "",
    idUsuarioPos: [""],
  });

  useEffect(() => {}, [renovarSesion]);

  const [cajeros, setCajeros] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [listado, setListado] = useState(false);
  const [sessionIsValid, setSessionIsValid] = useState(true);
  const getListaDeCajeros = async () => {
    let myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const current = await fetch(
      `${process.env.REACT_APP_Url}cajero`,
      requestOptions
    );
    if (current.status === 401) {
      logout();
    }
    const currentData = await current.json();
    setCajeros(currentData);
  };
  useEffect(() => {
    const getOwn = async () => {
      let myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const current =
        token &&
        (await fetch(`${process.env.REACT_APP_Url}cajero/own`, requestOptions));
      const currentData = current && (await current.json());
      setCajero(currentData);
    };

    token && getOwn();
  }, [token]);

  useEffect(() =>
    //Function that retrieves usuario
    {
      const getUsuarioId = async () => {
        if (cajero?.nombre === "") {
          return;
        }
        let myHeaders = new Headers();

        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const current =
          token &&
          (await fetch(
            `${process.env.REACT_APP_Url}usuario/${cajero.idUsuarioPos}`,
            requestOptions
          ));
        const currentData = await current?.json();
        const adminRole = currentData?.roles[0] === 1 ? true : false;
        setAdmin(adminRole);
      };
      token && getUsuarioId();
    }, [cajero, token]);

  useEffect(() => {
    if (token) {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        setSessionIsValid(false);
        console.log("Token expired.");
        logout();
      } else {
        setSessionIsValid(true);
      }
    }
  }, [token]);
  return token ? (
    <div style={{ maxWidth: "100vw" }}>
      <NavBar
        logout={logout}
        cajero={cajero}
        admin={admin}
        setListado={setListado}
        getListaDeCajeros={getListaDeCajeros}
      />

      {listado && (
        <Listado
          cajeros={cajeros}
          setCajeros={setCajeros}
          getListaDeCajeros={getListaDeCajeros}
        />
      )}
      <Footer />
    </div>
  ) : null;
};

export default Dashboard;
