import React, { useState } from "react";
import useAuth from "./context/useAuth";
import logo from "./logoSIGMA250.png";
import backImg from "./auth-bg.jpeg";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Login = () => {
  const { error, login, token, olvidoContrasena, setOlvidoContrasena } =
    useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cargando, setCargando] = useState(false);

  const handleSubmit = async (event) => {
    setCargando(true);
    event.preventDefault();
    if (!email || !password) {
      toast.error("Por favor ingrese email y contraseña");
      setCargando(false);
      return;
    }
    const newUser = { email, password };
    await login(newUser);
    error && console.log(error);
    setCargando(false);
  };

  const handleForgot = (event) => {
    event.preventDefault();
    setOlvidoContrasena(true);
  };

  return !token && !olvidoContrasena ? (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${backImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="section  is-flex is-align-items-center"
        style={{ minHeight: "100vh", paddingInline: "0" }}
      >
        <div className="container" style={{ padding: "0", maxWidth: "100vw" }}>
          <div
            className="columns is-flex is-justify-content-center"
            style={{ padding: "0", maxWidth: "100vw", margin: "0" }}
          >
            <figure
              className="mb-6"
              style={{
                maxHeight: "200px",
                maxWidth: "200px",
              }}
            >
              <img alt="Logo" src={logo} />
            </figure>
          </div>

          <div
            className="level is-flex is-justify-content-center"
            style={{ maxWidth: "100vw" }}
          >
            <div className="is-flex is-justify-content-center">
              <div className="card column" style={{ maxWidth: "80vw" }}>
                <div className="card-content">
                  <form onSubmit={handleSubmit} className="form container">
                    <div className="text-center mb-10">
                      <h1 className="title is-size-4 mb-5 mt-2">
                        Conexi&oacute;n
                      </h1>
                    </div>
                    <div>
                      <div className="fv-row mb-5 fv-plugins-icon-container">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email
                        </label>
                        <input
                          className="form-control has-background-white-ter form-control-lg "
                          type="email"
                          value={email}
                          maxLength="255"
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ borderColor: "white" }}
                        />
                        <div className="fv-plugins-message-container"></div>
                      </div>
                      <div className="fv-row mb-5 fv-plugins-icon-container">
                        <div className="is-flex is-justify-content-space-between  flex-stack mb-2">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Password
                          </label>

                          <button
                            onClick={handleForgot}
                            className="link-primary button is-ghost has-text-info fs-6 fw-bolder"
                          >
                            ¿La olvidaste?
                          </button>
                        </div>
                        <input
                          className="form-control has-background-white-ter form-control-lg form-control-solid"
                          type="password"
                          value={password}
                          maxLength="255"
                          autoComplete="off"
                          onChange={(e) => setPassword(e.target.value)}
                          style={{ borderColor: "white" }}
                        />
                        <div className="fv-plugins-message-container"></div>
                      </div>

                      <div className="fv-row">
                        {/* Error handling */}

                        <p
                          className="alert alert-danger"
                          style={{
                            margin: "25px 0 25px 0",
                            display: "none",
                          }}
                        ></p>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="button mb-4 is-info fw-bolder me-3 my-2"
                        >
                          <span className="indicator-label">
                            {cargando ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              "Conectarse"
                            )}
                          </span>
                          <span v-if="posting">
                            <span
                              className="spinner-border spinner-border-sm align-middle ms-2"
                              style={{ display: "none" }}
                            ></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default Login;
