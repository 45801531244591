import React, { useState } from "react";
import logo from "./logoSIGMA250.png";
import backImg from "./auth-bg.jpeg";
import { useNavigate, useSearchParams } from "react-router-dom";
import cambiarContrasena from "./ayudantes/cambiarContrasena";
import contrasenaEsCorrecta from "./ayudantes/verificarContrasena";
import useAuth from "./context/useAuth";

const ReestablecerContrasena = () => {
  const [contrasena, setContrasena] = useState("");
  const [confirmarContrasena, setConfirmarContrasena] = useState("");
  let [params] = useSearchParams();
  const [mensaje, setMensaje] = useState({
    display: "none",
    texto: "",
    color: "",
    exito: false,
  });
  const [spin, setSpin] = useState("none");
  const navigate = useNavigate();
  const token = params.get("token");
  const { logout } = useAuth();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (contrasena !== confirmarContrasena) {
      setMensaje({
        display: "flex",
        texto:
          "Por favor verifica que la contraseña y su confirmación sean iguales",
        color: "alert-danger",
        exito: false,
      });

      return;
    }

    if (!contrasenaEsCorrecta(contrasena)) {
      setMensaje({
        display: "flex",
        texto:
          "Por favor verifica que la contraseña cumpla con los requerimientos.",
        color: "alert-warning",
        exito: false,
      });

      return;
    }

    setSpin(true);

    const response = await cambiarContrasena({
      token: token,
      password: contrasena,
    });

    if (response.ok) {
      setMensaje({
        display: "flex",
        texto:
          "La contraseña se ha reestablecido con éxito. Por favor vuelve a ingresar.",
        color: "alert-success",
        exito: true,
      });
      setSpin("none");
      return;
    } else {
      setMensaje({
        display: "flex",
        texto:
          "Ha habido un error. Por favor intenta enviar un link de recuperación de la contraseña nuevamente.",
        color: "alert-warning",
        exito: true,
      });
      setSpin("none");
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${backImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="section  is-flex is-align-items-center"
        style={{ minHeight: "100vh", paddingInline: "0" }}
      >
        <div className="container" style={{ padding: "0", maxWidth: "100vw" }}>
          <div
            className="columns is-flex is-justify-content-center"
            style={{ padding: "0", maxWidth: "100vw", margin: "0" }}
          >
            <figure
              className="mb-6"
              style={{
                maxHeight: "200px",
                maxWidth: "200px",
              }}
            >
              <img alt="logo" src={logo} />
            </figure>
          </div>

          <div
            className="level is-flex is-justify-content-center"
            style={{ maxWidth: "100vw" }}
          >
            <div className="is-flex is-justify-content-center">
              <div className="card column" style={{ maxWidth: "25rem" }}>
                <div className="card-content">
                  <form onSubmit={handleSubmit} className="form container">
                    {mensaje.exito === false && (
                      <div className="text-center ">
                        <h1 className="title is-size-4 mb-5 pb-3 mt-2">
                          Reestablece tu contrase&ntilde;a{" "}
                        </h1>
                        <h3 className="subtitle is-small has-text-grey-light is-size-6 pb-4">
                          La nueva contrase&ntilde;a debe contener 8 caracteres
                          o m&aacute;s. Por lo menos un n&uacute;mero, una letra
                          may&uacute;scula y una letra min&uacute;scula.
                        </h3>
                      </div>
                    )}
                    <div>
                      {mensaje.exito === false && (
                        <div className="fv-row mb-5 fv-plugins-icon-container">
                          <label className="form-label fs-6 fw-bolder text-dark">
                            Nueva contrase&ntilde;a
                          </label>
                          <input
                            className="form-control has-background-white-ter form-control-lg "
                            type="password"
                            value={contrasena}
                            required
                            maxLength="255"
                            onChange={(e) => setContrasena(e.target.value)}
                            style={{ borderColor: "white" }}
                          />
                          <div className="fv-plugins-message-container"></div>
                        </div>
                      )}{" "}
                      {mensaje.exito === false && (
                        <div className="fv-row mb-5 fv-plugins-icon-container">
                          <label className="form-label fs-6 fw-bolder text-dark">
                            Confirma la nueva contrase&ntilde;a
                          </label>
                          <input
                            className="form-control has-background-white-ter form-control-lg "
                            type="password"
                            value={confirmarContrasena}
                            required
                            maxLength="255"
                            onChange={(e) =>
                              setConfirmarContrasena(e.target.value)
                            }
                            style={{ borderColor: "white" }}
                          />
                          <div className="fv-plugins-message-container"></div>
                        </div>
                      )}
                      <div className="fv-row">
                        {/* Error handling */}

                        <p
                          className={`alert ${mensaje.color}`}
                          style={{
                            margin: "25px 0 25px 0",
                            display: `${mensaje.display}`,
                          }}
                        >
                          {mensaje.texto}
                        </p>
                      </div>
                      <div className="text-center">
                        {mensaje.exito === false && (
                          <button
                            type="submit"
                            className="button mb-4 is-info fw-bolder me-3 my-2"
                          >
                            <span className="indicator-label">
                              Reestablecer
                            </span>
                            <span v-if="posting">
                              <span
                                className="spinner-border spinner-border-sm align-middle ms-2"
                                style={{ display: spin }}
                              ></span>
                            </span>
                          </button>
                        )}
                        {mensaje.exito && (
                          <button
                            type="button"
                            className="button mb-4 is-link fw-bolder me-3 my-2"
                            onClick={() => {
                              logout();
                              navigate("/");
                            }}
                          >
                            <span className="indicator-label">Regresar</span>
                            <span v-if="posting">
                              <span
                                className="spinner-border spinner-border-sm align-middle ms-2"
                                style={{ display: "none" }}
                              ></span>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReestablecerContrasena;
