import { Button, TableCell, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../context/useAuth";

const RenglonesBusqueda = ({ articulo, setMostrarDescuento, setArticulo, handleNuevoArticulo,infoArticulo,setInfoArticulo, setMostrarResultados  }) => {
  const total = (cantidad = 1, precio) => (+cantidad * +precio).toFixed(2);
  const [cantidad, setCantidad] = useState(1);
  const notifyCantidadNoDefinida = () =>
    toast.error("Por favor defina una cantidad");
    const notifyNoDisponibilidad = () =>
    toast.info("No hay disponibilidad de este artículo");
const { token } = useAuth();

    const validarCantidadService = async (almacen, codigo) => {
      let options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        redirect: "follow",
      };
      return fetch(
        `${process.env.REACT_APP_Url}common/pos/disponibilidadArticulo/${almacen}/${codigo}`,
        options
      );
    };
    const asignarArticulo = async (art) => {
      
      const cantidad = await validarCantidadService(
        art.codigoAlmacen,
        art.codArticulo
      )
        .then((data) => data.json())
        .then((res) => {
          return res;
        });
  
      if (cantidad === 0 || cantidad === undefined) {
        notifyNoDisponibilidad();
      } else {
        setInfoArticulo({
          ...infoArticulo,
          codArticulo: art.codArticulo,
          descripcion: art.descripcion,
          almacen: art.codigoAlmacen,
          precio: art.precio,
          cantidad: 1,
          cantidadMax: cantidad,
          itbm: art.itbm,
          total: art.precio,
          descuentoDesc: "",
          descuentoTotal: 0,
          descuentoCantidad: 0,
          descuentoUnitario: 0,
          articulo: art,
        });
        setMostrarResultados(false); 
        handleNuevoArticulo(true);
        
      }
    };

  const definirArticuloDescuento = () => {
    if (cantidad === 0) return notifyCantidadNoDefinida();
    setArticulo(articulo);
    setMostrarDescuento(true);
  };

  const agregarArticulo= (art) => {
    asignarArticulo(art);
    
  };


  return (
    <TableRow>
      <TableCell>{articulo?.descripcion}</TableCell>
      <TableCell>${articulo?.precio}</TableCell>
      <TableCell>${total(cantidad, articulo?.precio)}</TableCell>
      <TableCell>
        <Button color="success"
        onClick={() =>agregarArticulo(articulo) }>Agregar</Button>
      </TableCell>
    </TableRow>
  );
};

export default RenglonesBusqueda;
