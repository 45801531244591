import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "bulma/css/bulma.min.css";
import Pos from "./components/pos/Index.jsx";
import ReestablecerContrasena from "./components/ReestablecerContrasena";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Private from "./Private";
import { Main } from "./Main";
import { AuthProvider } from "./components/context/UserContext.jsx";
import { Dialog } from "@mui/material";
import RenovarSesion from "./components/RenovarSesion.jsx";
import useAuth from "./components/context/useAuth.js";
import { unregister } from "./Interceptors.js";

function App() {
  const {
    logout,
    refresh,
    renovarSesion,
    setCloseAllWindows,
    setRenovarSesion,
    setToken,
    token,
    vencimiento,
    testVencimiento,
  } = useAuth();

  const [start, setStart] = useState(false);
  const handleClose = () => setRenovarSesion(false);

  // useEffect(() => {}, [renovarSesion]);

  useEffect(() => {
    const time = (+vencimiento - 120) * 1000;
    const timer = token && setTimeout(() => setRenovarSesion(true), time);
    return () => {
      clearTimeout(timer);
    };
  }, [logout, setToken, start, vencimiento, setRenovarSesion, token]);

  return (
    <AuthProvider>
      <Dialog open={renovarSesion}>
        <RenovarSesion
          setStart={setStart}
          start={start}
          refresh={refresh}
          setRenovarSesion={setRenovarSesion}
          onClose={handleClose}
        />
      </Dialog>
      <ToastContainer position="top-left" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path={process.env.REACT_APP_ROUTE_authentication}>
            <Route
              path={process.env.REACT_APP_ROUTE_reset}
              element={<ReestablecerContrasena />}
            />
          </Route>
          <Route
            path={process.env.REACT_APP_ROUTE_pos}
            element={
              <Private>
                <Pos />
              </Private>
            }
          />
          <Route
            path="*"
            element={
              <main
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="container box">
                  <h1 className="title">Este url no existe.</h1>
                  <h2 className="subtitle">
                    Verifica la ruta que estás buscando
                  </h2>
                </div>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
export default App;
