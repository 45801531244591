const obtenerLinkParaContrasena = async (email) => {
  const url = `${process.env.REACT_APP_Url}usuario/forgotPassword`;

  let urlencoded = new URLSearchParams();
  urlencoded.append("usuario", email);
  const urlEncoded = new URLSearchParams(urlencoded);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: process.env.REACT_APP_Authorization,
    },
    body: urlEncoded,
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const { status, statusText, url } = response;
    throw Error(`${status}, ${statusText} in fetch ${url}`);
  }

  return response;
};
export default obtenerLinkParaContrasena;
