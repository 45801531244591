import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./components/context/useAuth";

const Private = ({ children }) => {
  const { token } = useAuth();
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default Private;
