const getUser = async (user) => {
  if (user) {
    const url = `${process.env.REACT_APP_Url}oauth/token`;
    const { email, password } = user;

    let urlencoded = new URLSearchParams();
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    urlencoded.append("grant_type", "password");
    const urlEncoded = new URLSearchParams(urlencoded);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: process.env.REACT_APP_Authorization,
      },
      body: urlEncoded,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      const { status, statusText, url } = response;
      alert("Ha ocurrido un error. Por favor verifique su contraseña.");
      throw Error(`${status}, ${statusText} in fetch ${url}`);
    }

    const data = await response.json();

    return data;
  }
};

export default getUser;
