import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import BuscarArticulos from "./BuscarArticulos.jsx";
import ResultadosDeBusqueda from "./ResultadosDeBusqueda.jsx";
import { Dialog } from "@mui/material";

const TablaArticulos = ({
  articulo,
  articulos,
  cantidad,
  setArticulos,
  handleShow,
  handleNuevoArticulo,
  handleCloseNuevoArticulo,
  setMostrarPago,
  setArticulo,
  infoArticulo,
  setInfoArticulo,
}) => {
  const [overflow, setOverFlow] = useState("");
  const [card, setCard] = useState("");
  const [test, settest] = useState("");
  const [scrollTop, setScrollTop] = React.useState(0);
  //Variable that lets us know if 'onMouseDown' or 'onMouseUp'
  const [pressed, setPressed] = React.useState("");
  //keeps the interval Id
  const [intervId, setIntervId] = React.useState("");
  //Change color when reached limit
  const [disableUp, setDisableUp] = React.useState(true);
  const [disableDown, setDisableDown] = React.useState("");

  const [resultadoDeArticulos, setResultadoDeArticulos] = React.useState([]);
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [cargandoResultadosBusqueda, setCargandoResultadosBusqueda] =
    useState(false);
  const cerrarResultados = () => setMostrarResultados(false);
  const scroll = (x) => {
    setPressed(x);
    setScrollTop(card.scrollTop);
  };

  const up = <FontAwesomeIcon icon={faChevronUp} size="1x" color="white" />;
  const down = <FontAwesomeIcon icon={faChevronDown} size="1x" color="white" />;

  useEffect(() => {
    const cardDom = document.querySelector(".el");
    setCard(cardDom);

    const heightDifference = card.clientHeight < card.scrollHeight;
    setOverFlow(heightDifference);
    settest(card.clientHeight);

    if (scrollTop === 0) {
      setDisableUp(true);
    } else {
      setDisableUp(false);
    }
    if (card.scrollHeight - card.scrollTop === card.clientHeight) {
      setDisableDown("is-danger");
    } else {
      setDisableDown("");
    }
    const start = (direction) => {
      setIntervId(setInterval(direction, 100));
    };

    const stop = () => {
      clearInterval(intervId);
    };

    if (pressed === "pressed") {
      start(() => (card.scrollTop += 30));
    }
    if (pressed === "pressedUp") {
      start(() => (card.scrollTop -= 30));
    }
    if (pressed === "released!") {
      stop();
    }

    function handleResize() {
      settest(card.clientHeight);
    }
    window.addEventListener("resize", handleResize);
  }, [overflow, card, pressed, test, articulos]);

  return (
    <>
      <Dialog
        open={mostrarResultados}
        onClose={cerrarResultados}
        fullWidth
        maxWidth="lg"
      >
        <ResultadosDeBusqueda
          resultados={resultadoDeArticulos}
          cargando={cargandoResultadosBusqueda}
          handleNuevoArticulo={handleNuevoArticulo}
          infoArticulo={infoArticulo}
          setInfoArticulo={setInfoArticulo}
          setMostrarResultados={setMostrarResultados}
        />
      </Dialog>
      <div
        className="container d-flex align-items-center justify-content-between block mt-1 row rounded border p-2 ml-1 mr-1"
        style={{ maxWidth: "35em" }}
      >
        <div className="container is-flex is-justify-content-space-between pr-3">
          <h4>
            {" "}
            <strong>Listado de artículos</strong>
          </h4>
          <div className="card-toolbar">
            <button
              type="button"
              className="button btn-icon btn-success mx-2 is-small"
              onClick={() => handleNuevoArticulo(true)}
            >
              <i className="fa fa-plus fs-4 "></i>
            </button>
          </div>
        </div>

        {/* <DefinirArticulos setArticulos={setArticulos} /> */}
        <BuscarArticulos
          setArticulos={setResultadoDeArticulos}
          setMostrarResultados={setMostrarResultados}
          setCargando={setCargandoResultadosBusqueda}
        />
      </div>
      <div
        className="el card mr-1 is-flex is-align-content-start is-justify-content-start"
        style={{ maxHeight: "75vh", overflow: "hidden", maxWidth: "35em" }}
      >
        <div className="row rounded border px-4" style={{ maxWidth: "70em" }}>
          {overflow && (
            <div
              style={{ minWidth: "100vw", minHeight: "2vh" }}
              className=" fixed-bottom is-flex is-justify-content-end mb-4"
            >
              <div
                className="btn-group-vertical mr-3 mb-5 py-6"
                role="group"
                aria-label="Botones para revelar el contenido en pantalla, arriba y abajo."
                style={{ maxWidth: "12vw" }}
              >
                <Button
                  className={`sticky-top top `}
                  variant="secondary"
                  onPointerDown={() => scroll("pressedUp")}
                  onPointerUp={() => scroll("released!")}
                  disabled={disableUp}
                >
                  {up}
                </Button>
                <Button
                  className={`sticky-top`}
                  onPointerDown={() => scroll("pressed")}
                  onPointerUp={() => scroll("released!")}
                  disabled={disableDown}
                >
                  {down}
                </Button>
              </div>
            </div>
          )}
          {articulo.length > 0 ? (
            <table
              id="kt_datatable_example_2"
              className="table table-striped table-row-bordered gy-5 gs-7 mt-0"
            >
              <thead className="fix-top sticky-top bg-light">
                <tr className="fw-bold fs-6 text-gray-800 ">
                  <th>Artículo</th>
                  <th>Cantidad</th>
                  <th>P/U</th>
                  <th>Total</th>
                </tr>
              </thead>
              {articulo?.map((articulo, index) =>
                !articulo.descuentoDesc ? (
                  <tbody key={index}>
                    <tr key={index} onClick={() => handleShow(articulo)}>
                      <td>{articulo.descripcion}</td>
                      <td>{articulo.cantidad}</td>
                      <td>${articulo.precio}</td>
                      <td>${articulo.total}</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody key={index}>
                    <tr key={index} onClick={() => handleShow(articulo)}>
                      <td>{articulo.descripcion}</td>
                      <td>{articulo.cantidad}</td>
                      <td>${articulo.precio}</td>
                      <td>${articulo.total}</td>
                    </tr>
                    <tr key={index + 1} onClick={() => handleShow(articulo)}>
                      <td>{articulo.descuentoDesc}</td>
                      <td>{articulo.descuentoCantidad}</td>
                      <td style={{ color: "red" }}>
                        -${articulo.descuentoUnitario}
                      </td>
                      <td style={{ color: "red" }}>
                        -${articulo.descuentoTotal}
                      </td>
                    </tr>
                  </tbody>
                )
              )}
            </table>
          ) : (
            <div className="d-flex flex-row">
              <div className="d-flex justify-content-start">
                <label className="form-label fs-6 fw-bolder text-secondary">
                  Seleccione un artículo
                </label>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-4">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="110"
                  height="110"
                  fill="#C8C8C8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-2">
        <div className="row">
          <div className="row ">
            <div className="col-sm py-2">
              <button
                className="button is-danger is-light"
                style={{ width: "100%" }}
              >
                Reimprimir
              </button>
            </div>
            <div className="col-sm py-2">
              <button
                className="button is-danger is-light"
                style={{ width: "100%" }}
              >
                Proforma
              </button>
            </div>
            <div className="col-sm py-2">
              <button
                className="button is-danger is-light"
                style={{ width: "100%" }}
              >
                Marbete
              </button>
            </div>
            <div className="col-sm py-2">
              <button
                onClick={() => setMostrarPago(true)}
                className="btn btn-success"
                style={{ width: "100%" }}
              >
                Pago
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TablaArticulos;
