import React, { useEffect, useState } from "react";
import useAuth from "../../context/useAuth";
import Select from "react-select";

const TipoCliente = ({
  cliente,
  setCliente,
  cajeroSeleccionado,
  setCajeroSeleccionado,
  setTipoCliente,
  setRefId,
}) => {
  const { logout, token, cajaDefault, refresh } = useAuth();
  const [optionsSelect, setOptionsSelect] = useState([
    { value: "", label: "" },
  ]);
  const [optionDef, setOptionDef] = useState();

  const handleChange = (e) => {
    setCajeroSeleccionado(e.label);
    setRefId(e.value);
    // refresh();
  };

  useEffect(() => {
    const getCliente = async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await fetch(
        `${process.env.REACT_APP_Url}common/pos/tipoCliente`,
        options
      );
      if (data.status === 401) {
        logout();
      }
      const response = await data.json();
      setCliente(response);
    };
    if (cliente === undefined) {
      getCliente();
    }
  }, [cliente, cajaDefault]);

  useEffect(() => {
    const getOptions = async () => {
      if (optionsSelect.length === 1) {
        cliente?.map((client, index) =>
          optionsSelect.push({
            label: client.descripcion,
            value: client.codigo,
          })
        );
      }
    };

    if (cliente !== undefined) {
      getOptions();
    }
  }, [cliente, optionsSelect]);

  useEffect(() => {
    const getDefault = async () => {
      const current = cliente?.filter(
        (cliente) => cliente.descripcion === cajeroSeleccionado
      );
      setTipoCliente(current?.[0]);
      if (cajeroSeleccionado === "Nombre cajero") {
        setCajeroSeleccionado(
          optionsSelect.find((t) => t.value === cajaDefault?.refIdClienteOtros)
            .label
        );
        setOptionDef(
          optionsSelect.find((t) => t.value === cajaDefault?.refIdClienteOtros)
        );
      }
    };
    if (optionsSelect.length > 1 && cajaDefault !== undefined) {
      getDefault();
    }
  }, [cliente, optionsSelect, cajaDefault, cajeroSeleccionado, optionDef]);
  if (cajeroSeleccionado === "Nombre cajero") {
    return null;
  } else
    return (
      <Select
        defaultValue={optionDef}
        Value={optionDef}
        onChange={(e) => handleChange(e)}
        options={optionsSelect}
      ></Select>
    );
};

export default TipoCliente;
