import React, { useState } from "react";
import Container from "@mui/material/Container";
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RenglonesBusqueda from "./RenglonesBusqueda";
import DescuentoEnBusqueda from "./DescuentoEnBusqueda";

const ResultadosDeBusqueda = ({
  resultados,
  handleNuevoArticulo,
  infoArticulo,
  setInfoArticulo,
  setMostrarResultados,
}) => {
  const [mostrarDescuento, setMostrarDescuento] = useState(false);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState(null);

  const cerrarDescuento = () => setMostrarDescuento(false);

  const mensajeResultados = () => {
    if (resultados?.length > 1)
      return `Se encontraron ${resultados?.length} resultados`;
    if (resultados?.length === 1)
      return `Se encontró  ${resultados?.length} resultado`;
    if (resultados.length === 0) return `No se encontraron resultados`;
  };
  return (
    <Container sx={{ p: 4, minHeight: "50vh" }}>
      <Dialog
        open={mostrarDescuento}
        onClose={cerrarDescuento}
        fullWidth
        maxWidth="lg"
      >
        <DescuentoEnBusqueda articulo={articuloSeleccionado} />
      </Dialog>
      <Typography variant="h4" align="center">
        {mensajeResultados()}
      </Typography>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Artículo</TableCell>
            <TableCell>P/U</TableCell>
            <TableCell>Total</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resultados?.map((articulo) => (
            <RenglonesBusqueda
              key={articulo.id}
              articulo={articulo}
              setArticulo={setArticuloSeleccionado}
              setMostrarDescuento={setMostrarDescuento}
              handleNuevoArticulo={handleNuevoArticulo}
              infoArticulo={infoArticulo}
              setInfoArticulo={setInfoArticulo}
              setMostrarResultados={setMostrarResultados}
            />
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ResultadosDeBusqueda;
