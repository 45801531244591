const registrar = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_Url}signup/correo`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
};

const eliminar = async (data, token) => {
  const url = `${process.env.REACT_APP_Url}usuario/${data}`;

  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const { status, statusText, url } = response;
    throw Error(`${status}, ${statusText} in fetch ${url}`);
  }
  return response;
};

export { eliminar, registrar };
