import React, { useState, useEffect } from "react";
import Listado from "../articulos*/Index";
import Facturacion from "./facturacion/Index";
import TablaArticulos from "./articulos/TablaArticulos";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import NotaCredito from "./notaCredito/index";
import useAuth from "../context/useAuth";
import Cliente from "./cliente/Cliente";
import DefinirArticulos from "./articulos/DefinirArticulos";
import Descuento from "./articulos/Descuento";
import Pago from "./Pago";
import CrearCliente from "./CrearCliente";
import { BroadcastChannel } from "broadcast-channel";
import { toast } from "react-toastify";

const channel = new BroadcastChannel("foobar");

const dataNotaCredito = [
  {
    codigoDGI: 666,
    codigo: 666,
    referencia: 2055,
    fecha: "14/02/22",
    tipo: "FAC",
    cliente: "cliente contado",
    ruc: "ruc",
    monto: 4.0,
  },
  {
    codigoDGI: 666,
    codigo: 666,
    referencia: 2055,
    fecha: "14/02/22",
    tipo: "FAC",
    cliente: "cliente contado",
    ruc: "ruc",
    monto: 4.0,
  },
];
const estadoInicial = {
  clientId: "0",
  codigo: "",
  compania: 1,
  docType: "",
  fechaFin: "",
  fechaIni: "",
  noReferencia: "",
  paciente: "",
  refId: "11",
  tipoDocto: "",
  page: "0",
  pageSize: "100",
};
const Pos = () => {
  const notifyNoArticulosFacturar = () =>
    toast.error("No hay articulos para facturar !");
  const notifyNoPago = () => toast.error("No hay pago definido !");
  const notifyFacturadoExitoso = () => {
    toast.success("Facturado con exito!");
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  };
  const notifyValorMenor = () =>
    toast.error("El total a pagar es mayor a lo ingresado!");
  const notifyNoFacturado = () => toast.error("Error al facturar !");
  const [articulos, setArticulos] = useState([]);
  const [refId, setRefId] = useState([]);
  const [articulo, setArticulo] = useState([]);
  const [buscarParam, setBuscarParam] = useState();
  const [show, setShow] = useState(false);
  const [conDescuento, setConDescuento] = useState("");
  const [close, setClose] = useState(false);
  channel.onmessage = (msg) => setClose(true);
  useEffect(() => close && logout(), [close]);

  const handleClose = () => setShow(false);
  const handleShow = (articulo) => {
    setShow(true);
    setArticuloSeleccionado(articulo);
  };
  const [showTable, setShowTable] = useState(false);
  const [mostrarNuevoArticulo, setMostrarNuevoArticulo] = useState(false);
  const handleCloseNuevoArticulo = () => {
    setMostrarNuevoArticulo(false);
    setInfoArticulo("");
    setMensajeDescuento("");
  };
  const handleCloseTable = () => {
    setShowTable(false);
    setBuscarParam(estadoInicial);
  };
  const [infoArticulo, setInfoArticulo] = useState({
    descripcion: "...",
    precio: "...",
    cantidad: 0,
    total: "...",
  });
  const [showCliente, setShowCliente] = useState(false);
  const [mostrarDescuento, setMostrarDescuento] = useState(false);
  const [mensajeDescuento, setMensajeDescuento] = useState();
  const handleCloseDescuento = () => {
    setMostrarDescuento(false);
  };
  const [mostrarPago, setMostrarPago] = useState(false);
  const handleClosePago = () => setMostrarPago(false);
  const [valorPago, setValorPago] = useState({ efectivo: 0, valePanama: 0 });
  const [crearCliente, setCrearCliente] = useState(false);
  const cerrarCrearCliente = () => setCrearCliente(false);
  const handleShowCliente = () => setShowCliente(true);
  const handleCloseCliente = () => setShowCliente(false);
  const handleShowTable = () => setShowTable(true);
  const [total, setTotal] = useState();
  const [dataCredito, setDataCredito] = useState(dataNotaCredito);
  const [obj, setObj] = useState();
  const [factura, setFactura] = useState();
  const [clientes, setClientes] = useState([{ name: "Andres", codigo: 1 }]);
  const [porCobrar, setPorCobrar] = useState(false);
  const [subRefType, setSubRefType] = useState("");
  const [completed, setCompleted] = useState(false);

  const {
    token,
    refreshToken,
    refresh,
    closeTab,
    cajaDefault,
    paramFacturaPos,
    cajero,
    logout,
  } = useAuth();

  //Cliente que aparece en la parte principal del POS, el default es 'Buscar cliente'
  const [articuloSeleccionado, setArticuloSeleccionado] = useState();
  const [cantidadDeArticuloSeleccionado, setCantidadDeArticuloSeleccionado] =
    useState({ cantidad: 1, total: 0 });
  const [clienteSeleccionado, setClienteSeleccionado] = useState({});
  const [buscarCliente, setbuscarCliente] = useState({
    code: "",
    idCompania: 1,
    name: "",
    refId: "1",
    referTo: "EMPL",
    ruc: "",
    subRefType: subRefType,
  });
  const [tipoCliente, setTipoCliente] = useState();

  useEffect(
    () =>
      setCantidadDeArticuloSeleccionado({
        cantidad: 1,
        total: articuloSeleccionado?.precio,
      }),
    [articuloSeleccionado]
  );

  useEffect(() => {
    const getRef = async () => {
      setBuscarParam(estadoInicial);

      if (refId !== undefined && buscarParam !== undefined) {
        buscarParam.refId = refId;
      }
    };
    if (refId !== undefined) {
      getRef();
    }
  }, [refId]);

  useEffect(() => {
    let mounted = true;
    // if (refId !== undefined) {
    //   buscarParam.refId = refId;
    //   mounted = true;
    // }

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(buscarParam),
      redirect: "follow",
    };
    if (buscarParam !== undefined)
      mounted &&
        fetch(
          `${process.env.REACT_APP_Url}common/pos/listaNotasCredito`,
          options
        )
          .then((data) => data.json())
          .then((res) => {
            setDataCredito(res.content);
            setTotal(res.totalElements);
            setObj(res);
          });
    closeTab && window.close();
    return () => (mounted = false);
  }, [buscarParam, closeTab, refId]);

  useEffect(() => {
    let mounted = true;
    if (cajaDefault !== undefined)
      setClienteSeleccionado({
        nombre: cajaDefault?.nombreCliente,
        codigo: cajaDefault?.idCliente,
        dv: cajaDefault?.dv,
        ruc: cajaDefault?.ruc,
      });
    if (tipoCliente?.referTo === "CXCO") {
      setPorCobrar(true);
    } else {
      setPorCobrar(false);
      setSubRefType("");
    }
    const objRequest = {
      ...buscarCliente,
      refId: tipoCliente?.codigo,
      referTo: tipoCliente?.referTo,
      idCompania: tipoCliente?.compania,
    };
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objRequest),
      redirect: "follow",
    };
    if (tipoCliente !== undefined)
      mounted &&
        fetch(`${process.env.REACT_APP_Url}common/pos/listaClientes`, options)
          .then((data) => data.json())
          .then((res) => {
            setClientes(res.content);
          });

    return () => (mounted = false);
  }, [buscarCliente, tipoCliente, subRefType, cajaDefault]);

  const [saveFactura, setSaveFactura] = useState({
    facturar: false,
    resCantidad: "",
    comentario: "",
    tipoFactura: "CO",
  });

  const [invoiceData, setInvoiceData] = useState({
    centroServicio: "",
    cjaTipoRec: "",
    clientId: "",
    clientName: "",
    clientRefId: "",
    clientType: "",
    codCaja: "",
    codCajero: "",
    companyId: "",
    createdBy: "",
    docDate: "",
    docType: "",
    dv: "",
    grossAmount: "",
    grossAmountGravable: "",
    modifiedBy: "",
    netAmount: "",
    noRecibo: "",
    observations: "",
    pageName: "",
    referenceId: "",
    referenceNo: "",
    ruc: "",
    subTotal: "",
    subTotalGravable: "",
    taxAmount: "",
    tipoFactura: "",
    totalDiscount: "",
    totalDiscountGravable: "",
    turno: "",
  });

  const [formaPago, setFormaPago] = useState([
    {
      fpCodigo: "",
      monto: "",
    },
  ]);

  const [articuloDataList, setArticuloDataList] = useState([
    {
      almacen: "",
      cantidad: "",
      codigo: "",
      compania: "",
      costo: "",
      descripcion: "",
      gravable: "",
      gravablePerc: "",
      idDescuento: "",
      other1: "",
      other2: "",
      other3: "",
      other4: "",
      precio: "",
      tipoArt: "",
      tipoDescuento: "",
      tipoServicio: "",
      total: "",
      totalDesc: "",
      totalItbm: "",
      valorDescuento: "",
    },
  ]);

  const [facturaFinal, setFacturaFinal] = useState({
    articuloDataList: articuloDataList,
    formaPago: formaPago,
    invoiceData: invoiceData,
  });

  useEffect(() => {
    let artDescData;
    let countArticulos = 0;
    let descuento = saveFactura.resCantidad.descuentoTotal
      ? saveFactura.resCantidad.descuentoTotal
      : 0;
    if (saveFactura?.facturar === true) {
      if (articulo === undefined || articulo.length <= 0) {
        notifyNoArticulosFacturar();
        setSaveFactura({
          ...saveFactura,
          facturar: false,
        });
        return;
      }
      //console.log(valorPago);
      if (valorPago === undefined || valorPago.efectivo <= 0) {
        notifyNoPago();
        setSaveFactura({ ...saveFactura, facturar: false });
        return;
      } else if (
        valorPago.efectivo <
        saveFactura?.resCantidad.total - descuento
      ) {
        notifyValorMenor();
        setSaveFactura({ ...saveFactura, facturar: false });
        return;
      } else {
        setFormaPago([
          {
            fpCodigo: "1",
            monto: "" + valorPago.efectivo,
          },
        ]);
      }

      articulo.map((art, key) => {
        countArticulos += 1;
        let codigoDesc;
        switch (art.tipoLetraDescuento) {
          case "E":
            codigoDesc = paramFacturaPos.tipoDescEmpl;
            break;
          case "J":
            codigoDesc = paramFacturaPos.tipoDescJubil;
            break;
          default:
            codigoDesc = "";
        }
        let itbmCalculado = 0;
        let itbmCalculadoDesc = 0;

        if (art.articulo.itbm === "S") {
          itbmCalculado =
            parseFloat(art.total) * parseFloat(art.articulo.gravablePerc);
          itbmCalculadoDesc =
            parseFloat(art.descuentoTotal) *
            parseFloat(art.articulo.gravablePerc);
        }

        let tmp = Math.round(itbmCalculado * 100);
        itbmCalculado = tmp / 100;
        tmp = Math.round(itbmCalculadoDesc * 100);
        itbmCalculadoDesc = tmp / 100;

        let articuloData = {
          almacen: art.articulo.codigoAlmacen,
          cantidad: "" + art.cantidad,
          codigo: "" + art.articulo.codArticulo,
          compania: "" + cajero.compania,
          costo: art.articulo.costo,
          descripcion: art.articulo.descripcion,
          gravable: art.articulo.itbm,
          gravablePerc: Math.round(+art.articulo.gravablePerc * 100) ,
          idDescuento: "",
          other1: "",
          other2: "",
          other3: "" + art.articulo.tipoArticulo,
          other4: "",
          precio: art.articulo.precio,
          tipoArt: "",
          tipoDescuento: "",
          tipoServicio: art.articulo.tipoServicio,
          total: "" + art.total,
          totalDesc: "",
          totalItbm: "" + itbmCalculado,
          valorDescuento: "",
        };
        if (art.descuentoTotal > 0) {
          countArticulos += 1;
          artDescData = { ...articuloData };
          artDescData.cantidad = "" + art.descuentoCantidad;
          artDescData.total = "";
          artDescData.descripcion = art.descuentoDesc;
          artDescData.totalDesc = "" + art.descuentoTotal;
          artDescData.valorDescuento = "" + art.descuentoUnitario;
          artDescData.idDescuento = "" + art.idDescuento;
          artDescData.tipoDescuento = art.tipoDescuento;
          artDescData.totalItbm = "" + itbmCalculadoDesc;
          artDescData.tipoArt = "D";
        }

        setInvoiceData({
          centroServicio: "" + paramFacturaPos?.cds,
          cjaTipoRec: "" + paramFacturaPos?.cjaTipoRec,
          clientId: "" + clienteSeleccionado?.codigo,
          clientName: "" + clienteSeleccionado?.nombre,
          clientRefId: "" + tipoCliente?.codigo,
          clientType: "" + tipoCliente?.referTo,
          codCaja: "" + saveFactura?.caja,
          codCajero: "" + cajero?.codigo,
          companyId: "" + cajero?.compania,
          createdBy: "" + cajero?.nombre,
          docDate: "" + cajaDefault?.fechaFactura,
          docType: "",
          dv: "" + clienteSeleccionado?.dv,
          grossAmount: "" + saveFactura?.resCantidad.subTotalE,
          grossAmountGravable: "" + saveFactura?.resCantidad.subTotalNoE,
          modifiedBy: "" + cajero?.nombre,
          netAmount: "" + saveFactura?.resCantidad.total,
          noRecibo: "",
          observations: saveFactura.comentario,
          pageName: "AllNewPos",
          referenceId: "",
          referenceNo: "",
          ruc: "" + clienteSeleccionado?.ruc,
          subTotal:
            "" +
            saveFactura?.resCantidad.subTotalE -
            +saveFactura?.resCantidad.descE,
          subTotalGravable:
            "" +
            saveFactura?.resCantidad.subTotalNoE -
            +saveFactura?.resCantidad.descNoE,
          taxAmount: "" + saveFactura?.resCantidad.itbmCalculado,
          tipoFactura: "" + saveFactura?.tipoFactura,
          totalDiscount: "" + saveFactura?.resCantidad.descE,
          totalDiscountGravable: "" + saveFactura?.resCantidad.descNoE,
          turno: "6560",
        });

        if (key === 0) {
          setArticuloDataList(() => [articuloData]);
          if (art.descuentoTotal > 0) {
            setArticuloDataList((prevArray) => [...prevArray, artDescData]);
          }
        } else if (key > 0) {
          setArticuloDataList((prevArray) => [...prevArray, articuloData]);
          if (art.descuentoTotal > 0) {
            setArticuloDataList((prevArray) => [...prevArray, artDescData]);
          }
        }

        // console.log(countArticulos);
      });
    }
    if (
      saveFactura?.facturar !== false &&
      countArticulos === articuloDataList.length &&
      articuloDataList?.[0].cantidad > 0
    ) {
      setFacturaFinal({
        articuloDataList: articuloDataList,
        formaPago: formaPago,
        invoiceData: invoiceData,
      });
      setCompleted(true);
    }
  }, [saveFactura, articuloDataList]);

  useEffect(async () => {
    if (completed === true) {
      let resp = await guardarFactura(facturaFinal);
      if (resp?.status === 201) {
        notifyFacturadoExitoso();
      } else {
        notifyNoFacturado();
      }
      setSaveFactura({ ...saveFactura, facturar: false });
      setCompleted(false);
    }
  }, [completed]);

  const guardarFactura = async (facturaFinal) => {
    // console.log(articuloDataList);
    console.log(facturaFinal);

    const response = await enviarFactura(facturaFinal)
      .then((data) => data)
      .then((res) => {
        return res;
      });

    return response;
  };

  const enviarFactura = async (facturaFinal) => {
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(facturaFinal),
    };
    return fetch(`${process.env.REACT_APP_Url}factura/facturar`, options);
  };

  return (
    <div
      className="container m-2"
      style={{ maxWidth: "100vw", maxHeight: "98vh" }}
    >
      <div className="columns is-multiline">
        <div className="column is-5">
          <Facturacion
            handleShowTable={handleShowTable}
            factura={factura}
            handleShowCliente={handleShowCliente}
            setbuscarCliente={setbuscarCliente}
            tipoCliente={tipoCliente}
            setTipoCliente={setTipoCliente}
            clienteSeleccionado={clienteSeleccionado}
            setRefId={setRefId}
            articulo={articulo}
            setCrearCliente={setCrearCliente}
            valorPago={valorPago}
            saveFactura={saveFactura}
            setSaveFactura={setSaveFactura}
          />
        </div>
        <div className="column is-7">
          <TablaArticulos
            articulos={articulos}
            articulo={articulo}
            setArticulos={setArticulos}
            setArticulo={setArticulo}
            handleShow={handleShow}
            handleNuevoArticulo={setMostrarNuevoArticulo}
            handleClose={handleCloseNuevoArticulo}
            cantidad={cantidadDeArticuloSeleccionado}
            setMostrarPago={setMostrarPago}
            infoArticulo={infoArticulo}
            setInfoArticulo={setInfoArticulo}
          />
        </div>

        <>
          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Listado
                articulo={articuloSeleccionado}
                setArticulo={setArticuloSeleccionado}
                cantidades={cantidadDeArticuloSeleccionado}
                setCantidad={setCantidadDeArticuloSeleccionado}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Guardar
              </Button>
              <Button variant="danger" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <Modal show={showTable} onHide={handleCloseTable} size="xl">
          <Modal.Body>
            <NotaCredito
              data={dataCredito}
              setData={setDataCredito}
              total={total}
              setBuscarParam={setBuscarParam}
              buscarParam={buscarParam}
              obj={obj}
              setFactura={setFactura}
              onHide={handleCloseTable}
            />
          </Modal.Body>
        </Modal>
        <Modal show={showCliente} onHide={handleCloseCliente} size="xl">
          <Modal.Body>
            <Cliente
              clientes={clientes}
              buscarCliente={buscarCliente}
              setbuscarCliente={setbuscarCliente}
              onHide={handleCloseCliente}
              setClientes={setClientes}
            />
          </Modal.Body>
        </Modal>
        <Modal show={showCliente} onHide={handleCloseCliente} size="xl">
          <Modal.Body>
            <Cliente
              clientes={clientes}
              setClientes={setClientes}
              buscarCliente={buscarCliente}
              setbuscarCliente={setbuscarCliente}
              porCobrar={porCobrar}
              subRefType={subRefType}
              setSubRefType={setSubRefType}
              onHide={handleCloseCliente}
              setClienteSeleccionado={setClienteSeleccionado}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={mostrarNuevoArticulo}
          onHide={handleCloseNuevoArticulo}
          size="xl"
        >
          <Modal.Body>
            <DefinirArticulos
              articulo={articulo}
              conDescuento={conDescuento}
              setArticulos={setArticulos}
              articulos={articulos}
              setArticulo={setArticulo}
              mensajeDescuento={mensajeDescuento}
              setMensajeDescuento={setMensajeDescuento}
              setMostrarDescuento={setMostrarDescuento}
              infoArticulo={infoArticulo}
              setInfoArticulo={setInfoArticulo}
              setConDescuento={setConDescuento}
            />
          </Modal.Body>
        </Modal>
        {/* Descuento */}
        <Modal show={mostrarDescuento} onHide={handleCloseDescuento} size="xl">
          <Modal.Body>
            <Descuento
              articulo={articulo}
              setMensajeDescuento={setMensajeDescuento}
              infoArticulo={infoArticulo}
              setInfoArticulo={setInfoArticulo}
              conDescuento={conDescuento}
              setConDescuento={setConDescuento}
              setArticulo={setArticulo}
              setMostrarDescuento={setMostrarDescuento}
            />
          </Modal.Body>
        </Modal>
        <Modal show={mostrarPago} onHide={handleClosePago} size="xl">
          <Modal.Body>
            <Pago
              setValorPago={setValorPago}
              setMostrarPago={setMostrarPago}
              valorPago={valorPago}
            />
          </Modal.Body>
        </Modal>
        <Modal show={crearCliente} onHide={cerrarCrearCliente} size="xl">
          <CrearCliente />
        </Modal>
      </div>
    </div>
  );
};
export default Pos;
