const contrasenaEsCorrecta = (password) => {
  if (password.length < 8) {
    return false;
  }
  let upperLetters = /[A-Z]/g;
  if (password.search(upperLetters) === -1) {
    return false;
  }

  let lowerLetters = /[a-z]/g;

  if (password.search(lowerLetters) === -1) {
    return false;
  }

  let numbers = /[0-9]/g;
  if (password.search(numbers) === -1) {
    return false;
  }
  return true;
};

export default contrasenaEsCorrecta;
