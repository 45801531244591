import { Button, Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import React, { useState } from "react";
import useAuth from "../context/useAuth";

const Pago = ({ setValorPago, setMostrarPago, valorPago }) => {
  const [efectivo, setEfectivo] = useState(0);
  const [provisional, setProvisional] = useState("")
  const checkTotalInput = (e) => {
    let result = e.target.value.replace("^(?=d*[1-9])d+$", "");
    if ((e.target.id = "efectivo")) {
      setValorPago({ ...valorPago, efectivo: result });
    }
  };

  const handleClose = () => {
    setMostrarPago(false);
  };

  const estilosDeBoton = {
    efectivo: "outlined",
    cheque: "outlined",
    tarjCredito: "outlined",
    ach: "outlined",
    tarjDebito: "outlined",
    valePanama: "outlined",
  };
  const [estilos, setEstilos] = useState(estilosDeBoton);
  const datosDePago = {
    formaPago: "",
    monto: "",
    tipoTarjeta: "",
    chequeReferencia: "",
    banco: "",
    tipoBanco: "",
  };
  const [datos, setDatos] = useState(datosDePago);
  const camposDeshabilitados = {
    efectivo: true,
    cheque: true,
    tarjCredito: true,
    ach: true,
    tarjDebito: true,
    valePanama: true,
  };
  const [camposActivos, setCamposActivos] = useState(camposDeshabilitados);

  const elegirFormaPago = (seleccion) => {
    setEstilos((prev) => {
      return { ...estilosDeBoton, [seleccion]: "contained" };
    });
    setDatos((prev) => {
      return { ...datosDePago, formaPago: seleccion };
    });
    setCamposActivos((prev) => {
      return { ...camposDeshabilitados, [seleccion]: false };
    });
  };
  const actualizarDatos = (e, seleccion) => {
    setDatos((prev) => {
      return { ...prev, [seleccion]: e.target.value };
    });
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container columns={12} spacing={8}>
          <Grid item xs={2}>
            <Button
              variant={estilos.efectivo}
              sx={{ minWidth: "8rem", mt: 1 }}
              onClick={() => elegirFormaPago("efectivo")}
            >
              {" "}
              Efectivo
            </Button>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Monto"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              variant="standard"
              size="small"
              disabled={camposActivos.efectivo}
              onChange={(e) => actualizarDatos(e, "monto")}
            ></TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                variant="standard"
                id="efectivo"
                label="0"
                onChange={(e) => checkTotalInput(e)}
                // defaultValue={valorPago.efectivo ? valorPago.efectivo : "0"}
                disabled={camposActivos.efectivo}
              ></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ pt: 4 }}>
        <Grid container spacing={3} columns={11}>
          <Grid item xs={2}>
            <Button
              variant={estilos.cheque}
              size="small"
              sx={{ minWidth: "8rem", mt: 2 }}
              onClick={() => elegirFormaPago("cheque")}
            >
              {" "}
              Cheque{" "}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Monto"
              variant="standard"
              size="small"
              disabled={camposActivos.cheque}
              onChange={(e) => actualizarDatos(e, "monto")}
            ></TextField>
          </Grid>{" "}
          <Grid item xs={2}>
            <TextField
              label="# Referencia"
              variant="standard"
              size="small"
              disabled={camposActivos.cheque}
              onChange={(e) => actualizarDatos(e, "chequeReferencia")}
            ></TextField>
          </Grid>{" "}
          <Grid item xs={2}>
            <TextField
              label="Banco"
              variant="standard"
              size="small"
              disabled={camposActivos.cheque}
              onChange={(e) => actualizarDatos(e, "banco")}
            ></TextField>
          </Grid>{" "}
          <Grid item xs={2}>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Tipo Banco</InputLabel>
              <Select
                disabled={camposActivos.cheque}
                onChange={(e) => actualizarDatos(e, "tipoBanco")}
                value={provisional}
              >
                <MenuItem value={""}>Ten</MenuItem>
                <MenuItem value={""}>Twenty</MenuItem>
                <MenuItem value={""}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant={estilos.tarjCredito}
              size="small"
              sx={{ minWidth: "8rem", mt: 2 }}
              onClick={() => elegirFormaPago("tarjCredito")}
            >
              {" "}
              Tarj. credito{" "}
            </Button>
          </Grid>
          <Grid item>
            <TextField
              label="Monto"
              variant="standard"
              size="small"
              disabled={camposActivos.tarjCredito}
              onChange={(e) => actualizarDatos(e, "monto")}
            ></TextField>
          </Grid>
          <Grid item>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel>Tipo Tarjeta</InputLabel>
              <Select
                disabled={camposActivos.tarjCredito}
                onChange={(e) => actualizarDatos(e, "tipoTarjeta")}
                value={provisional}
              >
                <MenuItem value={"Ten"}>Ten</MenuItem>
                <MenuItem value={"Twenty"}>Twenty</MenuItem>
                <MenuItem value={"Thirty"}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              label="# Referencia"
              variant="standard"
              size="small"
              disabled={camposActivos.tarjCredito}
            ></TextField>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item>
              <Button
                variant={estilos.ach}
                sx={{ minWidth: "8rem", mt: 1 }}
                onClick={() => elegirFormaPago("ach")}
              >
                {" "}
                ACH{" "}
              </Button>
            </Grid>
            <Grid item>
              <TextField
                label="Monto"
                variant="standard"
                size="small"
                disabled={camposActivos.ach}
                onChange={(e) => actualizarDatos(e, "monto")}
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                label="# Referencia"
                variant="standard"
                size="small"
                disabled={camposActivos.ach}
                onChange={(e) => actualizarDatos(e, "chequeReferencia")}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item>
              <Button
                variant={estilos.tarjDebito}
                size="small"
                sx={{ minWidth: "8rem", mt: 2 }}
                onClick={() => elegirFormaPago("tarjDebito")}
              >
                {" "}
                Tarj. Debito{" "}
              </Button>
            </Grid>
            <Grid item>
              <TextField
                label="Monto"
                variant="standard"
                size="small"
                disabled={camposActivos.tarjDebito}
                onChange={(e) => actualizarDatos(e, "monto")}
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                label="# Referencia"
                variant="standard"
                size="small"
                disabled={camposActivos.tarjDebito}
                onChange={(e) => actualizarDatos(e, "chequeReferencia")}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2} sx={{ pt: 2 }}>
              <Grid item>
                <Button
                  variant={estilos.valePanama}
                  size="small"
                  sx={{ minWidth: "8rem", mt: 2 }}
                  onClick={() => elegirFormaPago("valePanama")}
                >
                  Vale Panama
                </Button>
              </Grid>
              <Grid item>
                <TextField
                  label="Monto"
                  variant="standard"
                  size="small"
                  disabled={camposActivos.valePanama}
                  onChange={(e) => actualizarDatos(e, "monto")}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item align="center">
          <Button
            variant="outlined"
            color="success"
            sx={{ m: 2 }}
            onClick={() => handleClose()}
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pago;
