const valorInicial = {
  descripcion: "",
  codigo: "",
};

interface Action {
  type: string;
  payload?: string | number;
}

const reducer = (state: typeof valorInicial, { type, payload }: Action) => {
  switch (type) {
    case "descripcion":
      return {
        ...state,
        articulo: "",
        codArticulo: "",
        codigo: "",
        descripcion: payload,
      };
    case "codigo":
      return { ...state, articulo: "S", codigo: "C", codArticulo: payload };
    case "codigo de barras":
      return { ...state, articulo: "S", codigo: "B", codArticulo: payload };
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

export { valorInicial, reducer };
