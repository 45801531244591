import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TableBody from "@mui/material/TableBody";
import React, { useReducer, useState } from "react";
import { valorInicial, reducer } from "../reducers/reducer3.ts";
import useAuth from "../context/useAuth";
import { toast } from "react-toastify";

const CrearCliente = () => {
  const [state, dispatch] = useReducer(reducer, valorInicial);
  const [botonEstado, setBotonEstado] = useState("Aceptar");
  const { token } = useAuth();
  const postNuevoCliente = async () => {
    setBotonEstado(<CircularProgress />);
    const url = `${process.env.REACT_APP_Url}common/pos/crearClienteParticular`;
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state),
    };
    const respuesta = await fetch(url, options);

    setBotonEstado("Aceptar");
    if (!respuesta.ok) {
      toast.error("Ha habido un error.");
      throw new Error(
        `${respuesta.status} en fetch ${options.method} a la url ${url}`
      );
    }
    console.log("respuesta", respuesta);
    toast.success(`El cliente ${state.descripcion} ha sigo agregado`);
  };

  return (
    <Container sx={{ p: 2 }}>
      <Grid container justifyContent="center">
        <Typography variant="h4">Creación de cliente particular</Typography>
      </Grid>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">
                  Tipo cliente
                </InputLabel>
                <Select
                  value={state.tipoCliente}
                  onChange={(e) =>
                    dispatch({
                      type: "tipoCliente",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"Empleados"}>Empleados</MenuItem>
                  <MenuItem value={"Cuentas x cobrar otros"}>
                    Cuentas x cobrar otros
                  </MenuItem>
                  <MenuItem value={"Colaborador otros"}>
                    Colaborador otros
                  </MenuItem>
                  <MenuItem value={"Médico"}>Médico</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align={"left"}>
              <TextField
                label="Nombre"
                variant="standard"
                size="small"
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                label="Ruc"
                variant="standard"
                size="small"
                onChange={(e) => {
                  dispatch({ type: "ruc", payload: e.target.value });
                  console.log("the state", state);
                }}
              ></TextField>
            </TableCell>
            <TableCell>
              <TextField label="DV" variant="standard" size="small"></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <InputLabel id="demo-simple-select-label">
                  ¿Es colaborador?
                </InputLabel>
                <Select
                  value={state.colaborador}
                  onChange={(e) =>
                    dispatch({ type: "colaborador", payload: e.target.value })
                  }
                >
                  <MenuItem value={"Si"}>Si</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
                <InputLabel id="demo-simple-select-label">
                  ¿Aplica descuento?
                </InputLabel>
                <Select value={"Si"}>
                  <MenuItem value={"Si"}>Si</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <InputLabel id="demo-simple-select-label">
                  ¿Forma de pago?
                </InputLabel>
                <Select
                  value={state.formaPago}
                  onChange={(e) =>
                    dispatch({ type: "formaPago", payload: e.target.value })
                  }
                >
                  <MenuItem value={"Credito"}>Crédito</MenuItem>
                  <MenuItem value={"Contado"}>Contado</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <InputLabel id="demo-simple-select-label">
                  Días crédito
                </InputLabel>
                <Select
                  value={state.diasCrLimite}
                  onChange={(e) =>
                    dispatch({ type: "diasCrLimite", payload: e.target.value })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <TextField
                label="Monto limite"
                variant="standard"
                size="small"
                value={state.montoCrLimite}
                onChange={(e) =>
                  dispatch({ type: "montoCrLimite", payload: e.target.value })
                }
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <InputLabel id="demo-simple-select-label">
                  Facturar al costo
                </InputLabel>
                <Select
                  value={state.facturarAlCosto}
                  onChange={(e) =>
                    dispatch({
                      type: "facturarAlCosto",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"Si"}>Si</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  value={state.estado}
                  onChange={(e) =>
                    dispatch({ type: "estado", payload: e.target.value })
                  }
                >
                  <MenuItem value={"activo"}>Activo</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Responsable</TableCell>
            <TableCell>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <InputLabel id="demo-simple-select-label">
                  Tipo cliente
                </InputLabel>
                <Select
                  value={state.usuarioCreacion}
                  onChange={(e) =>
                    dispatch({
                      type: "usuarioCreacion",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"Empleados"}>Empleados</MenuItem>
                  <MenuItem value={"Cuentas x cobrar otros"}>
                    Cuentas x cobrar otros
                  </MenuItem>
                  <MenuItem value={"Colaborador otros"}>
                    Colaborador otros
                  </MenuItem>
                  <MenuItem value={"Médico"}>Médico</MenuItem>
                </Select>
              </FormControl>
            </TableCell>

            <TableCell>
              <TextField label="" variant="standard" size="small"></TextField>
            </TableCell>
            <TableCell>
              <TextField label="" variant="standard" size="small"></TextField>
            </TableCell>
            <TableCell>
              <Button variant="contained" size="small">
                <Typography variant="h6"> ...</Typography>
              </Button>
            </TableCell>
          </TableRow>
          <TableRow></TableRow>
        </TableBody>
      </Table>
      <Button
        variant="contained"
        color="success"
        sx={{ m: 2 }}
        onClick={postNuevoCliente}
      >
        {botonEstado}
      </Button>
    </Container>
  );
};

export default CrearCliente;
