import React, { useEffect, useState } from "react";
import CajerosRegistrados from "./CajerosRegistrados";
import CajerosSinRegistrar from "./CajerosSinRegistrar";
import PrecioDinamico from "./PrecioDinamico";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Listado = ({ cajeros, setCajeros, getListaDeCajeros }) => {
  useEffect(() => {}, [cajeros]);
  const [show, setShow] = useState(false);
  const [cajeroSelect, setcajeroSelect] = useState(false);
  const [save, setSave] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ display: "flex", gridTemplateColumns: "repeat(8, 1fr)" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Compania</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Correo</TableCell>
              <TableCell>Agregar/Eliminar</TableCell>
              <TableCell>Configurar precios dinamicos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cajeros.map((cajero) =>
              cajero.usuarioPos === null ? (
                <CajerosSinRegistrar
                  cajero={cajero}
                  key={cajero.codigo}
                  getListaDeCajeros={getListaDeCajeros}
                />
              ) : (
                <CajerosRegistrados
                  cajero={cajero}
                  key={cajero.codigo}
                  getListaDeCajeros={getListaDeCajeros}
                  setShow={setShow}
                  setcajeroSelect={setcajeroSelect}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Configurar precio dinamico</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrecioDinamico
            setSave={setSave}
            save={save}
            cajeroSelect={cajeroSelect}
            show={show}
            setShow={setShow}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setSave(true);
            }}
          >
            Guardar
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Listado;
