import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { valorInicial, reducer } from "../../reducers/reducer2.ts";
import useAuth from "../../context/useAuth";

const BuscarArticulos = ({ setArticulos, setMostrarResultados, handleNuevoArticulo }) => {
  const [state, dispatch] = useReducer(reducer, valorInicial);
  const [cargando, setCargando] = useState(false);
  const { token, logout } = useAuth();
  const [tipoCodigo, setTipoCodigo] = useState("codigo de barras");
  const [checked, setChecked] = React.useState(true);

  const actualizarCodigo = () =>
    dispatch({ type: tipoCodigo, payload: state.codArticulo });

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      setTipoCodigo("codigo");
    }
    if (!checked) {
      setTipoCodigo("codigo de barras");  
    }
  };
  useEffect(() => {
    actualizarCodigo();
  }, [checked]);
  
  const notificarParametrosVacios = () =>
    toast.error("Defina un artículo o un código para iniciar la búsqueda");

  const getArticulos = async (setter) => {
    const data = {
      almacen: "1",
      almacenCds: "",
      artType: "",
      articulo: state.articulo,
      checkAvailability: "",
      clase: "",
      codArticulo: state.codArticulo,
      codigo: state.codigo,
      compania: 1,
      defaultQty: "",
      descripcion: state.descripcion,
      docId: "",
      drugFamily: "",
      esMenuDia: "Y",
      familia: "",
      familiaClase: "",
      idUsuario: "",
      itbm: "",
      page: 0,
      pageSize: 0,
      search: "",
      showTechDesc: "",
      tipo: "",
    };
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const dataArticulos = await fetch(
      `${process.env.REACT_APP_Url}common/pos/articulosCafeteria`,
      options
    );
    if (dataArticulos.status === 401) {
      logout();
    }
    if (!dataArticulos.ok) {
      console.error(
        `Ha habido un error en fetch status ${dataArticulos.status}`
      );
    }
    const articulos = await dataArticulos.json();
    setter(articulos.content);
  };

  const handleClick = async () => {
    if (state.descripcion === "" && state.codigo === "") {
      notificarParametrosVacios();
      return;
    } else {
      setCargando(true);
      await getArticulos(setArticulos);
      setCargando(false);
      setMostrarResultados(true);
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid>
        <TextField
          label="Artículo"
          variant="outlined"
          sx={{ width: 207, mr: 2 }}
          value={state.descripcion || ""}
          onChange={(e) => {
            state.codigo="";
            dispatch({ type: "descripcion", payload: e.target.value })
          }}
        />

        <TextField
          label="Código"
          variant="outlined"
          sx={{ width: 200 }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={state.codArticulo || ""}
          onChange={(e) =>{
            state.descripcion="";
            dispatch({ type: tipoCodigo, payload: e.target.value });
          }
            
          }
        />
      </Grid>
      <Button
        onClick={handleClick}
        size="small"
        variant="contained"
        color="primary"
      >
        {cargando ? <CircularProgress color="inherit" /> : "Buscar"}
      </Button>
      <FormControlLabel
        value={checked}
        control={<Checkbox defaultChecked />}
        label="Buscar por código de barras"
        onChange={handleChecked}
      />
    </Grid>
  );
};

export default BuscarArticulos;
