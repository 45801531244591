interface InitialData {
  aplicaDescuento: string;
  colaborador: string;
  compania: 0;
  descripcion: string;
  diasCrLimite: 0;
  dv: string;
  estado: string;
  facturarAlCosto: string;
  formaPago: string;
  montoCrLimite: 0;
  refIdResp: string;
  refTypeResp: 0;
  ruc: string;
  tipoCliente: string;
  usuarioCreacion: string;
}

const valorInicial: InitialData = {
  aplicaDescuento: "No",
  colaborador: "No",
  compania: 0,
  descripcion: "",
  diasCrLimite: 0,
  dv: "",
  estado: "activo",
  facturarAlCosto: "Si",
  formaPago: "Credito",
  montoCrLimite: 0,
  refIdResp: "",
  refTypeResp: 0,
  ruc: "",
  tipoCliente: 'Empleados',
  usuarioCreacion: "Empleados",
};
interface Action {
  type: string;
  payload?: string;
}

const reducer = (state: typeof valorInicial, { type, payload }: Action) => {
  switch (type) {
    case "aplicaDescuento":
      return { ...state, aplicaDescuento: payload };
    case "colaborador":
      return { ...state, colaborador: payload };
    case "compania":
      return { ...state, compania: payload };
    case "descripcion":
      return { ...state, descripcion: payload };
    case "diasCrLimite":
      return { ...state, diasCrLimite: payload };
    case "dv":
      return { ...state, dv: payload };
    case "estado":
      return { ...state, estado: payload };
    case "facturarAlCosto":
      return { ...state, facturarAlCosto: payload };
    case "formaPago":
      return { ...state, formaPago: payload };
    case "montoCrLimite":
      return { ...state, montoCrLimite: payload };
    case "refIdResp":
      return { ...state, refIdResp: payload };
    case "refTypeResp":
      return { ...state, refTypeResp: payload };
    case "ruc":
      return { ...state, ruc: payload };
    case "tipoCliente":
      return { ...state, tipoCliente: payload };
    case "usuarioCreacion":
      return { ...state, usuarioCreacion: payload };
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

export { valorInicial, reducer };
