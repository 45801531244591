import React, { useState, useEffect } from "react";
import useAuth from "../../context/useAuth";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { createArray } from "../../ayudantes/createArray";
import { toast } from "react-toastify";
import {
  Pagination,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

const DefinirArticulos = ({
  setArticulos,
  articulos,
  conDescuento,
  setArticulo,
  articulo,
  mensajeDescuento,
  setMostrarDescuento,
  infoArticulo,
  setInfoArticulo,
  setMensajeDescuento,
  setConDescuento,
}) => {
  const [familia, setFamilia] = useState();
  const notifyCeroArticulos = () =>
    toast.info("La cantidad debe ser mayor a 0 !");
  const notifyNoArticulos = () => toast.error("Selecccione un articulo !");
  const notifyAddArticulos = () => toast.info("Articulo agregado !");
  const notifyDescuentoSupera = () =>
    toast.error("El precio con descuento es mayor al precio original!");
  const notifyNoDisponibilidad = () =>
    toast.info("No hay disponibilidad de este artículo");
  const notifySuperaDisponibilidad = (cantidadMaxima) =>
    toast.info("Supera la cantidad maxima disponible (" + cantidadMaxima + ")");
  const [renglonFamilia, setRenglonFamilia] = useState();
  const [renglonClase, setRenglonClase] = useState();
  const [renglonArticulos, setRenglonArticulos] = useState();
  const [codigoFamilia, setCodigoFamilia] = useState("");
  const [codigoFamiliaBusqueda, setCodigoFamiliaBusqueda] = useState("");
  const [clase, setClase] = useState();
  const [nombreClase, setNombreClase] = useState("");
  const [codigoClase, setCodigoClase] = useState("");
  const { token, refreshToken, cajero, refresh, logout } = useAuth();
  const [field, setField] = useState([]);
  const [allowEdit, setAllowEdit] = useState(false);

  const [arrayPaginas, setArrayPaginas] = useState([1, 2, 3, 4, 5]);
  const [arrayPaginasClase, setArrayPaginasClase] = useState([1, 2, 3, 4, 5]);
  const [arrayPaginasArticulos, setArrayPaginasArticulos] = useState([]);
  const [paginasArticulos, setPaginasArticulos] = useState();

  const [page, setPage] = useState({
    familia: 1,
    clase: 1,
    articulos: 1,
  });

  const descuento = () => {
    //console.log(infoArticulo?.descripcion);
    //Verificar que el array articulo contiene el articulo en cuestion
    if (
      infoArticulo.descripcion === "..." ||
      infoArticulo.descripcion === "" ||
      infoArticulo.descripcion === undefined
    ) {
      notifyNoArticulos();
      return;
    }
    setMostrarDescuento(true);
  };

  /* const handleClick = () => {
    let indexOfArticulo = articulo.findIndex(
      (e) => e.descripcion === infoArticulo.descripcion
    );
    if (indexOfArticulo === -1) {
      setArticulo([...articulo, infoArticulo]);
      alert("El articulo se ha añadido");
      return;
    }
    setMostrarDescuento(true);
  };*/

  const eliminarDescuento = () => {
    setInfoArticulo({
      ...infoArticulo,
      descuentoDesc: "",
      descuentoTotal: 0,
      descuentoCantidad: 0,
      descuentoUnitario: 0,
    });
  };

  const handleClick = () => {
    if (
      infoArticulo.descripcion === "" ||
      infoArticulo.descripcion === undefined
    ) {
      notifyNoArticulos();
      return;
    }
    if (infoArticulo.cantidad <= 0 || infoArticulo.cantidad === undefined) {
      notifyCeroArticulos();
      return;
    }

    let indexOfArticulo = articulo.findIndex(
      (e) => e.descripcion === infoArticulo.descripcion
    );

    if (indexOfArticulo === -1) {
      setArticulo([...articulo, infoArticulo]);

      setInfoArticulo({
        ...infoArticulo,
        codArticulo: 0,
        descripcion: "...",
        cantidad: 0,
        cantidadMax: 0,
        precio: 0,
        itbm: 0,
        total: 0,
        descuentoDesc: "",
        descuentoTotal: 0,
        descuentoCantidad: 0,
        descuentoUnitario: 0,
      });
      notifyAddArticulos();
      // return;
    } else if (articulo[indexOfArticulo].total !== infoArticulo.total) {
      if (
        articulo[indexOfArticulo].cantidad + +infoArticulo.cantidad >
        infoArticulo.cantidadMax
      ) {
        notifySuperaDisponibilidad(infoArticulo.cantidadMax);
      } else {
        setArticulo([...articulo, infoArticulo]);

        setInfoArticulo({
          ...infoArticulo,
          codArticulo: 0,
          descripcion: "...",
          cantidad: 0,
          cantidadMax: 0,
          precio: 0,
          itbm: 0,
          total: 0,
          descuentoDesc: "",
          descuentoTotal: 0,
          descuentoCantidad: 0,
          descuentoUnitario: 0,
        });
        notifyAddArticulos();
      }
    } else {
      const newArray = articulo;
      if (
        newArray[indexOfArticulo].cantidad + +infoArticulo.cantidad >
        infoArticulo.cantidadMax
      ) {
        notifySuperaDisponibilidad(infoArticulo.cantidadMax);
      } else {
        newArray[indexOfArticulo].cantidad =
          +newArray[indexOfArticulo].cantidad + +infoArticulo.cantidad;
        newArray[indexOfArticulo].total =
          +newArray[indexOfArticulo].cantidad * +infoArticulo.precio;

        //descuentos
        newArray[indexOfArticulo].descuentoDesc = infoArticulo.descuentoDesc;
        newArray[indexOfArticulo].descuentoCantidad =
          +newArray[indexOfArticulo].descuentoCantidad +
          +infoArticulo.descuentoCantidad;
        newArray[indexOfArticulo].descuentoTotal =
          +newArray[indexOfArticulo].descuentoCantidad *
          +infoArticulo.descuentoUnitario;
        newArray[indexOfArticulo].descuentoUnitario =
          infoArticulo.descuentoUnitario;

        setArticulo(newArray);
        notifyAddArticulos();
        setInfoArticulo({
          ...infoArticulo,
          codArticulo: 0,
          descripcion: "...",
          cantidad: 0,
          cantidadMax: 0,
          precio: 0,
          itbm: 0,
          total: 0,
          descuentoDesc: "",
          descuentoTotal: 0,
          descuentoCantidad: 0,
          descuentoUnitario: 0,
        });
      }
    }
  };
  const definirRenglon = (arr, i, setRenglon, limit = 5) => {
    const start = i === 0 ? 0 : i * limit;
    const end = i === arr?.length ? arr?.length : start + limit;
    setRenglon(arr?.slice(start, end));
  };

  const checkTotalInput = (e) => {
    let result = e.target.value.replace("^(?=d*[1-9])d+$", "");
    updateTotal(result);
  };

  const checkTotalDiscountInput = (e) => {
    let result = e.target.value.replace("^(?=d*[1-9])d+$", "");
    updateTotalDiscount(result);
  };

  const updatePrice = (e) => {
    let result = e.target.value; //.replace("^(?=d*[1-9])d+$", "");
    if (result.length <= 0) {
      result = 0;
      return;
    }
    let temp = infoArticulo.articulo;
    temp.precio = result;

    const grandTotal = (
      Math.round(result * +infoArticulo.cantidad * 100) / 100
    ).toFixed(2);
    setInfoArticulo({
      ...infoArticulo,
      precio: result,
      total: grandTotal,
      articulo: temp,
    });
  };

  const updateTotalDiscount = (result) => {
    if (+result !== NaN) {
      let grandTotal = (
        Math.round(infoArticulo.descuentoUnitario * +result * 100) / 100
      ).toFixed(2);
      if (+grandTotal < infoArticulo.total) {
        setInfoArticulo({
          ...infoArticulo,
          descuentoTotal: grandTotal,
          descuentoCantidad: result,
        });
      } else {
        notifyDescuentoSupera();
        setInfoArticulo({
          ...infoArticulo,
          descuentoTotal: infoArticulo.descuentoUnitario,
          descuentoCantidad: 1,
        });
      }
    } else {
      setInfoArticulo({
        ...infoArticulo,
        precio: 0,
        descuentoTotal: 0,
        descuentoCantidad: 0,
      });
    }
  };

  const updateTotal = (result) => {
    if (+result !== NaN) {
      if (+result > infoArticulo.cantidadMax) {
        notifySuperaDisponibilidad(infoArticulo.cantidadMax);
      } else {
        const grandTotal = (
          Math.round(infoArticulo.precio * +result * 100) / 100
        ).toFixed(2);
        setInfoArticulo({
          ...infoArticulo,
          cantidad: result,
          total: grandTotal,
        });
      }
    } else {
      setInfoArticulo({
        ...infoArticulo,
        codArticulo: 0,
        precio: 0,
        cantidad: 0,
        cantidadMax: 0,
        total: 0,
        itbm: 0,
        descuentoDesc: "",
        descuentoTotal: 0,
        descuentoCantidad: 0,
        descuentoUnitario: 0,
      });
    }
  };

  const getCodigo = async (e) => {
    const current = await clase?.filter(
      (clas) => clas.familia === e.target.value
    );
    const codigo = await current[0]?.codClase;
    setCodigoClase(codigo);
  };
  const asignarArticulo = async (art) => {
    const cantidad = await validarCantidadService(
      art.codigoAlmacen,
      art.codArticulo
    )
      .then((data) => data.json())
      .then((res) => {
        return res;
      });

    if (cantidad === 0 || cantidad === undefined) {
      notifyNoDisponibilidad();
    } else {
      setInfoArticulo({
        ...infoArticulo,
        codArticulo: art.codArticulo,
        descripcion: art.descripcion,
        almacen: art.codigoAlmacen,
        precio: art.precio,
        cantidad: 1,
        cantidadMax: cantidad,
        itbm: art.itbm,
        total: art.precio,
        descuentoDesc: "",
        descuentoTotal: 0,
        descuentoCantidad: 0,
        descuentoUnitario: 0,
        articulo: art,
      });
    }
  };
  //articuloseleccionado

  useEffect(() => {
    // updateTotal(infoArticulo?.cantidad);
    if (infoArticulo === undefined) {
      setInfoArticulo({
        ...infoArticulo,
        codArticulo: 0,
        descripcion: "...",
        cantidad: 0,
        cantidadMax: 0,
        precio: 0,
        itbm: 0,
        total: 0,
        descuentoDesc: "",
        descuentoTotal: 0,
        descuentoCantidad: 0,
        descuentoUnitario: 0,
      });
    }
  }, [infoArticulo]);

  //actualizar familia por codigo

  useEffect(() => {
    if (
      infoArticulo.cantidad > 0 &&
      codigoFamiliaBusqueda === "" &&
      field.length > 0
    ) {
      setCodigoFamiliaBusqueda("" + infoArticulo.articulo.codFlia);
    }
  }, [infoArticulo, field]);

  // cantidad de articulos

  const validarCantidadService = async (almacen, codigo) => {
    let options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    return fetch(
      `${process.env.REACT_APP_Url}common/pos/disponibilidadArticulo/${almacen}/${codigo}`,
      options
    );
  };

  useEffect(() => {
    if (field.length > 0) {
      if (field.includes(codigoFamiliaBusqueda)) {
        setAllowEdit(true);
      } else {
        setAllowEdit(false);
      }
    }
  }, [codigoFamiliaBusqueda, field]);

  useEffect(() => {
    if (field.length > 0) {
      if (field.includes(codigoFamilia)) {
        setAllowEdit(true);
      } else {
        setAllowEdit(false);
      }
      setInfoArticulo({
        codArticulo: 0,
        cantidad: 0,
        cantidadMax: 0,
        precio: 0,
        total: 0,
        itbm: 0,
        descuentoDesc: "",
        descuentoTotal: 0,
        descuentoCantidad: 0,
        descuentoUnitario: 0,
      });
      // console.log(allowEdit);
    }
  }, [codigoFamilia]);

  useEffect(() => {
    const toGetFamilias = async () => {
      const current = await getFamilias(cajero.codigo);
      if (current.status === 401) {
        logout();
      } else if (current.status === 200) {
        const currentData = await current.json();
        if (currentData?.listadoFamilias.length > 0) {
          setField(currentData?.listadoFamilias);
        }
      }
    };
    if (field.length === 0) {
      toGetFamilias();
    }
  }, [infoArticulo, codigoFamilia]);
  const getFamilias = async (cajeroSelect) => {
    let options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_Url}precioDinamico/${cajeroSelect}`,
      options
    );
  };
  //Familia de articulos:
  useEffect(() => {
    let mounted = true;
    setArticulos();
    setCodigoClase();
    setClase();
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    mounted &&
      fetch(`${process.env.REACT_APP_Url}common/pos/familiaArticulo`, options)
        .then((data) => data.json())
        .then((res) => {
          setFamilia(res);
          setArrayPaginas(createArray(res?.length, 5));
          setRenglonFamilia(res?.slice(0, 5));
        });

    return () => (mounted = false);
  }, []);
  //Clase de articulos:
  useEffect(() => {
    let mounted = true;
    setArticulos();
    //setClase();
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    mounted &&
      codigoFamilia &&
      fetch(
        `${process.env.REACT_APP_Url}common/pos/claseArticulo?familia=${codigoFamilia}&compania=${cajero.compania}`,
        options
      )
        .then((data) => data.json())
        .then((res) => {
          if (res.status === 401) {
            logout();
          }
          setClase(res);
          setArrayPaginasClase(createArray(res?.length, 10));
          setRenglonClase(res?.slice(0, 10));
        });

    return () => (mounted = false);
  }, [codigoFamilia]);
  //Articulos cafeteria:
  useEffect(() => {
    let mounted = true;
    setArticulos();

    const data = {
      almacen: "1",
      almacenCds: "",
      artType: "A",
      articulo: "",
      checkAvailability: "",
      clase: "",
      codArticulo: "",
      codigo: codigoClase,
      compania: 1,
      defaultQty: "",
      descripcion: "",
      docId: "",
      drugFamily: "",
      esMenuDia: "Y",
      familia: codigoFamilia,
      familiaClase: "",
      idUsuario: "",
      itbm: "",
      page: 0,
      pageSize: 0,
      search: "",
      showTechDesc: "",
      tipo: "",
    };
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    mounted &&
      codigoClase &&
      fetch(
        `${process.env.REACT_APP_Url}common/pos/articulosCafeteria`,
        options
      )
        .then((data) => data.json())
        .then((res) => {
          setArticulos(res?.content);
          const paginas = Math.ceil(res?.content.length / 30);
          setPaginasArticulos(paginas);
          setRenglonArticulos(res?.content.slice(0, 30));
        });

    return () => (mounted = false);
  }, [codigoClase, codigoFamilia]);

  if (infoArticulo === undefined) {
    return null;
  }
  return (
    <Container>
      <Row>
        <Card style={{ minwidth: "100%" }}>
          <table
            id="kt_datatable_example_2"
            className="table table-striped table-row-bordered gy-5 gs-7 mt-0"
          >
            <thead className="fix-top sticky-top bg-light">
              <tr className="fw-bold fs-6 text-gray-800 ">
                <th>Artículo</th>
                <th>Cantidad</th>
                <th>Desc</th>
                <th>P/U</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{infoArticulo?.descripcion}</td>
                <td>
                  <input
                    className="input is-small"
                    onChange={(e) => checkTotalInput(e)}
                    value={infoArticulo?.cantidad || ""}
                    type="number"
                    min="1"
                    style={{ maxWidth: "4rem" }}
                  ></input>
                </td>
                <td>
                  <Button onClick={descuento} variant="secondary">
                    $
                  </Button>
                </td>
                <td>
                  {allowEdit && infoArticulo?.precio ? (
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Unitario
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        value={infoArticulo?.precio || ""}
                        onChange={(e) => updatePrice(e)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        label="Amount"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <InputLabel disabled htmlFor="outlined-adornment-amount">
                        Unitario
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        value={infoArticulo?.precio || ""}
                        onChange={(e) => updatePrice(e)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        label="Amount"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        disabled
                      />
                    </FormControl>
                  )}
                </td>
                <td>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel disabled htmlFor="outlined-adornment-amount">
                      Total
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={infoArticulo?.total || ""}
                      onChange={(e) => updatePrice(e)}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      label="Amount"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      disabled
                    />
                  </FormControl>
                </td>
              </tr>
              {infoArticulo?.descuentoDesc && (
                <tr>
                  <td>{infoArticulo?.descuentoDesc}</td>
                  <td>
                    <input
                      className="input is-small"
                      onChange={(e) => checkTotalDiscountInput(e)}
                      value={infoArticulo?.descuentoCantidad || ""}
                      type="number"
                      min="1"
                      style={{ maxWidth: "4rem" }}
                    ></input>
                  </td>
                  <td>
                    <Button
                      variant="outline-danger"
                      onClick={eliminarDescuento}
                    >
                      X
                    </Button>
                  </td>
                  <td>-${infoArticulo?.descuentoUnitario}</td>
                  <td>-${infoArticulo?.descuentoTotal}</td>
                </tr>
              )}
            </tbody>
          </table>
          <Button onClick={handleClick}>Añadir artículo</Button>
        </Card>
        <Col>
          <Row>
            <Col>
              <Button variant="success" style={{ margin: 6 }}>
                Familia
              </Button>
              <Button variant="warning">Clase</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ padding: "1rem" }}>
                <h2>Familia</h2>
                <Container>
                  <Col>
                    <Pagination
                      count={arrayPaginas.length}
                      boundaryCount={9}
                      page={page.familia}
                      onChange={(e, p) => {
                        setPage((prev) => {
                          return { ...prev, familia: p };
                        });
                        definirRenglon(familia, p - 1, setRenglonFamilia);
                      }}
                    ></Pagination>
                    <div className="buttons">
                      {renglonFamilia?.map((fam, index) => (
                        <Button
                          className=" button is-small  "
                          key={index}
                          size="sm"
                          onClick={() => setCodigoFamilia(fam.codFlia)}
                          variant="success"
                        >
                          {fam.nombre}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Container>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ padding: "1rem" }}>
                <h2>Clase</h2>
                <Container>
                  <Col>
                    {clase?.length > 10 && (
                      <Pagination
                        count={arrayPaginasClase.length}
                        boundaryCount={9}
                        page={page.clase}
                        onChange={(e, p) => {
                          definirRenglon(clase, p - 1, setRenglonClase, 10);
                          setPage((prev) => {
                            return { ...prev, clase: p };
                          });
                        }}
                      ></Pagination>
                    )}
                    <div className="buttons">
                      {renglonClase?.map((clas, index) => (
                        <Button
                          key={index}
                          onClick={() => setCodigoClase(clas.codClase)}
                          variant="warning"
                          className=" button is-small  "
                        >{clas.familia}</Button>
                      ))}
                    </div>
                  </Col>
                </Container>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ padding: "1rem" }}>
                <h2>Artículos</h2>
                <Container>
                  <Col>
                    {articulos?.length > 25 && (
                      <Pagination
                        count={paginasArticulos}
                        boundaryCount={9}
                        page={page.articulos}
                        onChange={(e, p) => {
                          definirRenglon(
                            articulos,
                            p - 1,
                            setRenglonArticulos,

                            30
                          );
                          setPage((prev) => {
                            return { ...prev, articulos: p };
                          });
                        }}
                      ></Pagination>
                    )}
                  </Col>
                  <div className="buttons">
                    {renglonArticulos?.map((art, index) => (
                      <Button
                        key={index}
                        className="button is-small is-responsive is-fullwidth block"
                        onClick={() => {
                          asignarArticulo(art);
                          setMensajeDescuento("");
                        }}
                      >
                        {art.descripcion.substring(0, 80)}
                        {art.descripcion.length >= 80 && "..."}
                      </Button>
                    ))}
                  </div>
                </Container>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default DefinirArticulos;
