import React from "react";
import OlvideContrasena from "./components/OlvideContrasena";
import Dashboard from "./components/Dashboard/Index";
import Login from "./components/Login";

export const Main = () => {
  return (
    <>
      <div className="App" style={{ maxWidth: "100vw", maxHeight: "100vh" }}>
        <Login />
      </div>
      <OlvideContrasena />
      <Dashboard />
      <div />
    </>
  );
};
