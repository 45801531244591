import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

const Listado = ({ articulo, cantidades, setCantidad, setArticulo }) => {
  const price = articulo?.precio;
  //price es un valor que debe mantenerse para que esta operacion funcione
  const decrease = () => {
    if (cantidades.cantidad === 0) {
      return;
    }
    setCantidad({
      ...cantidades,
      cantidad: cantidades.cantidad - 1,
      total: +cantidades.total - +price,
    });
  };
  const increase = () => {
    setArticulo({ ...articulo, cantidad: articulo.cantidad + 1 });
    // setCantidad({
    //   ...cantidades,
    //   total: +cantidades.total + +price,
    // });
  };

  return (
    <div className="col-sm card" style={{ width: "100%" }}>
      <div className="card-body px-4">
        <div className="row py-4 d-flex align-items-center justify-content-between">
          <div className="col-sm-1" style={{ textAlign: "right" }}>
            <a className="btn btn-icon btn-dark" style={{ height: "20%" }}>
              <i className="fa fa-trash fs-4 "></i>
            </a>
          </div>
          <div className="col-sm " style={{ maxWidth: "10rem" }}>
            <img
              alt="abstract 2"
              src="assets/media/svg/shapes/abstract-2.svg"
            ></img>
          </div>
          <div className="col-sm pt-2" style={{ maxWidth: "20%" }}>
            <p>
              <strong>Precio/U</strong>
            </p>
            <p className="pt-2">${articulo?.precio}</p>
            <p className="py-2">
              <strong>{articulo?.descripcion}</strong>
            </p>
          </div>
          <div className="col-sm " style={{ maxWidth: "10rem" }}>
            <p>
              <strong>Cantidad</strong>
            </p>
            <div
              className="input-group "
              data-kt-dialer="true"
              data-kt-dialer-min="1"
              data-kt-dialer-max="50"
              data-kt-dialer-step="1"
              data-kt-dialer-prefix="#"
              id="#kt_dialer_example_1"
            >
              <button
                className="btn btn-icon btn-outline btn-outline-secondary"
                type="button"
                data-kt-dialer-control="decrease"
                onClick={decrease}
              >
                <i className="fa fa-solid fa-minus"></i>
              </button>

              <input
                type="text"
                className="form-control"
                value={articulo.cantidad}
                data-kt-dialer-control="input"
                readOnly
                onChange={(e) =>
                  setArticulo({ ...articulo, cantidad: e.target.value })
                }
              />

              <button
                className="btn btn-icon btn-outline btn-outline-secondary"
                type="button"
                data-kt-dialer-control="increase"
                onClick={increase}
              >
                <i className="fa fa-solid fa-plus"></i>
              </button>
            </div>
            <p style={{ paddingTop: "1rem" }}>
              <strong>Total</strong>
            </p>
            <span>${cantidades.total}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listado;
