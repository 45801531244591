import React, { useState } from "react";
import useRegistrarCajero from "../../hooks/useRegistrarCajero";
import { TableCell, TableRow, Input, Checkbox } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";

const CajerosSinRegistrar = ({ cajero, getListaDeCajeros }) => {
  const { dispatch } = useRegistrarCajero();
  const [usuario, setUsuario] = useState();
  const [loading, setLoading] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleClick = () => {
    if (!validateEmail(usuario)) {
      alert("Verifique el correo electronico");
      return;
    }
    setLoading("is-loading");
    dispatch({
      type: "registrar",
      payload: { idCajero: +cajero.codigo, nombreUsuario: usuario },
    });
    setTimeout(() => getListaDeCajeros(), 500);
  };

  return (
    <TableRow
      key={cajero.codigo}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        maxWidth: "1024px",
      }}
    >
      <TableCell width={"5%"}>{cajero.nombre}</TableCell>
      <TableCell>{cajero.usuario}</TableCell>
      <TableCell>{cajero.nombreCompania}</TableCell>
      <TableCell>{cajero.codigo}</TableCell>
      <TableCell>
        <Input onChange={(e) => setUsuario(e.target.value)}></Input>
      </TableCell>
      <TableCell width="5%" align="right">
        {" "}
        <button
          style={{ margin: "auto", display: "flex" }}
          className={`button is-success ${loading}`}
          onClick={() => handleClick()}
        >
          +
        </button>{" "}
      </TableCell>
      <TableCell>
        {" "}
        <button
          disabled={true}
          style={{ margin: "auto", display: "flex" }}
          className={`button is-primary is-small is-success ${loading}`}
        >
          <i className="fa fa-cog fa-fw fa-2x" style={{ color: "white" }}></i>
        </button>
      </TableCell>
    </TableRow>
  );
};

export default CajerosSinRegistrar;
