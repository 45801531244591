import React, { useState } from "react";
import useAuth from "./context/useAuth";
import logo from "./logoSIGMA250.png";
import backImg from "./auth-bg.jpeg";
import obtenerLinkParaContrasena from "./ayudantes/obtenerLinkParacontrasena";
import { toast } from "react-toastify";

const OlvideContrasena = () => {
  const { token, olvidoContrasena, setOlvidoContrasena } = useAuth();
  const [email, setEmail] = useState("");
  const [spin, setSpin] = useState("none");
  const [sent, setSent] = useState(false);
  const [mensaje, setMensaje] = useState({
    display: "none",
    texto: "",
    color: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpin("flex");
    if (email === "") {
      toast.error("Por favor ingrese un email");
      setSpin("hidden");
      return;
    }
    const { status } = await obtenerLinkParaContrasena(email);

    if (!status) {
      setMensaje({
        display: "flex",
        texto: "Ha ocurrido un error de conexión. Por favor intente más tarde.",
        color: "alert-warning",
      });
      return;
    }

    setMensaje({
      display: "flex",
      texto: "Hemos enviado un link a tu correo.",
      color: "alert-success",
    });

    setSent(true);
  };

  const onCancel = () => {
    setOlvidoContrasena(false);
    setMensaje({
      display: "none",
      texto: "",
      color: "",
    });
    setSent(false);
    setSpin("none");
    setEmail("");
  };
  return !token && olvidoContrasena ? (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${backImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="section  is-flex is-align-items-center"
        style={{ minHeight: "100vh", paddingInline: "0" }}
      >
        <div className="container" style={{ padding: "0", maxWidth: "100vw" }}>
          <div
            className="columns is-flex is-justify-content-center"
            style={{ padding: "0", maxWidth: "100vw", margin: "0" }}
          >
            <figure
              className="mb-6"
              style={{
                maxHeight: "200px",
                maxWidth: "200px",
              }}
            >
              <img alt="logo" src={logo} />
            </figure>
          </div>

          <div
            className="level is-flex is-justify-content-center"
            style={{ maxWidth: "100vw" }}
          >
            <div className="is-flex is-justify-content-center">
              <div className="card column" style={{ maxWidth: "80vw" }}>
                <div className="card-content">
                  <form onSubmit={handleSubmit} className="form container">
                    <div className="text-center ">
                      <h1 className="title is-size-4 mb-5 pb-3 mt-2">
                        ¿Olvidaste la contrase&ntilde;a ?
                      </h1>
                      <h3 className="subtitle is-small has-text-grey-light is-size-6">
                        Por favor ingresa tu correo para reestablecerla.
                      </h3>
                      <br></br>
                    </div>
                    <div>
                      <div className="fv-row mb-5 fv-plugins-icon-container">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email
                        </label>
                        <input
                          className="form-control has-background-white-ter form-control-lg "
                          type="email"
                          value={email}
                          maxLength="255"
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ borderColor: "white" }}
                        />
                        <div className="fv-plugins-message-container"></div>
                      </div>

                      <div className="fv-row">
                        {/* Error handling */}

                        <p
                          className={`alert ${mensaje.color}`}
                          style={{
                            margin: "25px 0 25px 0",
                            display: `${mensaje.display}`,
                          }}
                        >
                          {mensaje.texto}
                        </p>
                      </div>

                      <div className="text-center">
                        {!sent && (
                          <button
                            type="submit"
                            className="button mb-4 is-info fw-bolder me-3 my-2"
                          >
                            <span className="indicator-label">
                              Enviar enlace
                            </span>
                            <span v-if="posting">
                              <span
                                className="spinner-border spinner-border-sm align-middle ms-2"
                                style={{ display: spin }}
                              ></span>
                            </span>
                          </button>
                        )}
                        {sent && (
                          <button
                            type="button"
                            className="button mb-4 is-info fw-bolder me-3 my-2"
                            onClick={onCancel}
                          >
                            <span className="indicator-label">Regresar</span>
                            <span v-if="posting">
                              <span
                                className="spinner-border spinner-border-sm align-middle ms-2"
                                style={{ display: "none" }}
                              ></span>
                            </span>
                          </button>
                        )}

                        <button
                          className="button mb-4 is-info fw-bolder me-3 my-2 is-light"
                          onClick={onCancel}
                          type="button"
                        >
                          <span className="indicator-label ">Cancelar</span>
                          <span v-if="posting">
                            <span
                              className="spinner-border spinner-border-sm align-middle ms-2"
                              style={{ display: "none" }}
                            ></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default OlvideContrasena;
