const cambiarContrasena = async (data) => {
  const url = `${process.env.REACT_APP_Url}usuario/changePassword`;

  const options = {
    method: "POST",
    //mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_Authorization,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const { status, statusText, url } = response;
    throw Error(`${status}, ${statusText} in fetch ${url}`);
  }
  return response;
};
export default cambiarContrasena;
