import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAuth from "../../context/useAuth";
import TipoCliente from "./TipoCliente";
import moment from "moment";
import "moment-timezone";
import { toast } from "react-toastify";
import Select from "react-select";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

const notifyCalendarBack = () => toast.error("No puede cambiar la fecha !");
const notifyFacturado = () => toast.success("Producto facturado");
const notifyNoCaja = () => {
  toast.error("Sr. Usuario: esta PC no tiene asignado un número de caja!");
  setTimeout(function () {
    window.close();
  }, 5000);
};
const handleRefresh = () => {
  // by calling this method react re-renders the component
  window.location.reload();
};

const Facturacion = ({
  handleShowCliente,
  handleShowTable,
  factura,
  tipoCliente,
  setTipoCliente,
  clienteSeleccionado,
  setRefId,
  articulo,
  setCrearCliente,
  valorPago,
  saveFactura,
  setSaveFactura,
}) => {
  const { logout, refresh, token, cajero, cajaDefault, tipoClienteDef } =
    useAuth();
  const [overflow, setOverFlow] = useState("");
  const [card, setCard] = useState("");
  const [test, settest] = useState(card.clientHeight);
  const [caja, setCaja] = useState();
  const [estacion, setEstacion] = useState([]);
  const [estacionSelect, setEstacionSelect] = useState([
    { value: "", label: "" },
  ]);
  const [estacionSelectDef, setEstacionSelectDef] = useState();
  const [cajeroSeleccionado, setCajeroSeleccionado] = useState("Nombre cajero");
  const [cliente, setCliente] = useState();
  const [cajaSelect, setCajaSelect] = useState([{ value: "", label: "" }]);
  const [cajaSelectDef, setCajaSelectDef] = useState();
  const [impresora, setImpresora] = useState();
  const [impresoraSelect, setImpresoraSelect] = useState([
    { value: "", label: "" },
  ]);
  const [impresoraSelectDef, setImpresoraSelectDef] = useState();
  //Variables resumen totales
  const [resCantidad, setResCantidad] = useState({
    subTotalE: (0).toFixed(2),
    descE: (0).toFixed(2),
    subTotalNoE: (0).toFixed(2),
    descNoE: (0).toFixed(2),
    subTotal: (0).toFixed(2),
    itbmCalculado: (0).toFixed(2),
    total: (0).toFixed(2),
    pago: (0).toFixed(2),
    cambio: (0).toFixed(2),
    articulos: 0,
  });

  //Guarda la fecha excogida.

  let defaultDate = moment(cajaDefault?.fechaFactura).format("YYYY-MM-DD");
  const [date, setDate] = useState(defaultDate);
  const onSetDate = (event) => {
    if (cajaDefault?.permiteModificarFechaPos !== "N") {
      notifyCalendarBack();
      var dateChecked = moment(event.target.value).format("YYYY-MM-DD");
      setDate(dateChecked);
    } else {
      notifyCalendarBack();
    }
  };
  let [tipoPago, setTipoPago] = useState("CO");
  //setSaveFactura({...saveFactura, tipoFactura:tipoPago });
  function onChangeValue(event) {
    setTipoPago(event.target.value);
    setSaveFactura({ ...saveFactura, tipoFactura: tipoPago });
  }
  useEffect(() => {
    const cardDom = document.querySelector(".card");
    setCard(cardDom);
    const heightDifference = card.clientHeight < card.scrollHeight;
    setOverFlow(heightDifference);
    function handleResize() {
      settest(card.clientHeight);
    }
    window.addEventListener("resize", handleResize);
    //El array de dependencias tenia card.clientHeight, card.scrollHeight, causando un error en la consola. Se reemplaza por card
  }, [overflow, test, card]);

  //Llama los servicios para mostrar info en cada elemento de la forma
  useEffect(() => {
    const getCaja = async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await fetch(
        `${process.env.REACT_APP_Url}common/pos/caja`,
        options
      );
      if (data.status === 401) {
        logout();
      }
      const response = await data.json();
      if (response.status === 500) {
        notifyNoCaja();
      } else {
        response.sort((a, b) => a.id - b.id);
        setCaja(response);
      }
    };
    let mounted = true;
    mounted && getCaja();
    return () => (mounted = false);
  }, [token]);

  useEffect(() => {
    const getOptions = async () => {
      if (cajaSelect.length === 1) {
        caja?.map((caja, index) =>
          cajaSelect.push({
            label: caja.descripcion,
            value: caja.id,
          })
        );
        setCajaSelectDef(cajaSelect?.[1]);
        setSaveFactura({ ...saveFactura, caja: cajaSelect?.[1].value });
      }
    };

    if (caja !== undefined) {
      getOptions();
    }
  }, [caja, cajaSelect, cajaSelectDef]);
  //Impresora

  useEffect(() => {
    const getImpresora = async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await fetch(
        `${process.env.REACT_APP_Url}common/pos/impresora`,
        options
      );
      const response = await data.json();
      setImpresora(response);
    };
    let mounted = true;
    mounted && getImpresora();
    return () => (mounted = false);
  }, [token]);

  useEffect(() => {
    const getImpresoraOptions = async () => {
      if (impresoraSelect.length === 1) {
        impresora?.map((impresora, index) =>
          impresoraSelect.push({
            label: impresora.labelCol,
            value: impresora.keyCol,
          })
        );
        setImpresoraSelectDef(impresoraSelect?.[1]);
        setSaveFactura({
          ...saveFactura,
          impresora: impresoraSelect?.[1].value,
        });
      }
    };

    if (impresora !== undefined) {
      getImpresoraOptions();
    }
  }, [impresora, impresoraSelect, impresoraSelectDef]);
  //estacion
  useEffect(() => {
    const getEstacion = async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await fetch(
        `${process.env.REACT_APP_Url}common/estacion`,
        options
      );
      const response = await data.json();
      setEstacion(response);
    };
    let mounted = true;
    mounted && getEstacion();
    return () => (mounted = false);
  }, [token]);

  useEffect(() => {
    const getEstacionOptions = async () => {
      if (estacionSelect.length === 1) {
        estacion?.map((estacion, index) =>
          estacionSelect.push({
            label: estacion.nombre,
            value: estacion.id,
          })
        );
        setEstacionSelectDef(estacionSelect?.[1]);
      }
    };

    if (estacion !== undefined) {
      getEstacionOptions();
    }
  }, [estacion, estacionSelect, estacionSelectDef]);

  useEffect(() => {
    if (valorPago.efectivo !== undefined) {
      let total = +valorPago.efectivo;
      setResCantidad(
        resCantidad,
        (resCantidad.pago = total.toFixed(2)),
        (resCantidad.cambio = (+resCantidad.pago - +resCantidad.total).toFixed(
          2
        ))
      );
    }
  }, [valorPago]);

  useEffect(() => {
    if (articulo.length > 0) {
      let [
        cantidadTmp,
        totalTmp,
        totalItbm,
        totDescNoE,
        totSubNoE,
        totDescE,
        totSubtoE,
        totItbmDesc,
      ] = [0, 0, 0, 0, 0, 0, 0, 0];
      articulo.forEach((data) => {
        cantidadTmp += +data.cantidad;
        if (data.itbm === "S") {
          totalItbm +=
            +data.cantidad * +data.total * +data.articulo.gravablePerc;
          totDescNoE += +data.descuentoTotal * +data.descuentoCantidad;
          totSubNoE += +data.cantidad * +data.total;
          totItbmDesc +=
            +data.descuentoTotal *
            +data.descuentoCantidad *
            +data.articulo.gravablePerc;
        } else {
          totDescE += +data.descuentoTotal;
          totSubtoE += +data.cantidad * +data.total;
        }
        totalTmp += +data.total - +data.descuentoTotal;
      });

      setResCantidad(
        resCantidad,
        (resCantidad.articulos = cantidadTmp),
        (resCantidad.total = (+totalTmp + +totalItbm + +totItbmDesc).toFixed(
          2
        )),
        (resCantidad.itbmCalculado = (totalItbm + totItbmDesc).toFixed(2)),
        (resCantidad.descNoE = totDescNoE.toFixed(2)),
        (resCantidad.subTotalNoE = totSubNoE.toFixed(2)),
        (resCantidad.descE = totDescE.toFixed(2)),
        (resCantidad.subTotalE = totSubtoE.toFixed(2)),
        (resCantidad.cambio = +resCantidad.pago - +resCantidad.total)
      );
    }
  }, [articulo]);
  //El array de dependencias en 296 era [articulo.length, ...articulo.map((v) => v.cantidad)], esto causaba un error en la consola
  const updateComentario = (e) => {
    setSaveFactura({ ...saveFactura, comentario: e.target.value });
  };

  const updateCaja = (e) => {
    setSaveFactura({ ...saveFactura, caja: e.value });
  };

  const updateImpresora = (e) => {
    setSaveFactura({ ...saveFactura, impresora: e.value });
  };

  const guardarFactura = () => {
    setSaveFactura({
      ...saveFactura,
      facturar: true,
      resCantidad: resCantidad,
    });
  };

  if (
    cajaSelectDef === undefined ||
    impresoraSelectDef === undefined ||
    estacionSelectDef === undefined
  ) {
    return null;
  }
  return (
    <>
      <div className="row rounded border px-2">
        <div className="row">
          <Col>
            <p className="form-label font-weight-bold">
              <strong>Tipo Factura</strong>
            </p>

            <div
              className="form-check form-check-custom form-check-solid form-check-lg"
              style={{ padding: "0 0rem" }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="CO"
                  name="radio-buttons-group"
                  onChange={onChangeValue}
                >
                  <FormControlLabel
                    value="CO"
                    control={<Radio />}
                    label="CONTADO"
                  />
                  <FormControlLabel
                    value="CR"
                    control={<Radio />}
                    label="CRÉDITO"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Col>

          <Col>
            <label htmlFor="exampleFormControlInput1" className=" form-label">
              <strong>Fecha</strong>
            </label>
            <input
              type="date"
              className="input"
              value={date}
              onChange={onSetDate}
            ></input>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="m-1"
              size="sm"
              style={{ width: "10em" }}
            >
              FACTURA
            </Button>
            <Button
              className="btn btn-danger m-1"
              style={{ width: "10em" }}
              size="sm"
              onClick={handleShowTable}
            >
              NOTA CRÉDITO
            </Button>
          </Col>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            {factura && (
              <Col className="mr-6" style={{ maxWidth: "fit-content" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={factura}
                  readOnly
                />
              </Col>
            )}
          </Row>
        </div>
      </div>

      <div className="row rounded border ">
        <div className="row ">
          <Row>
            <div className="col-sm py-1">
              <label htmlFor="exampleFormControlInput1" className=" form-label">
                Tipo Cliente
              </label>

              <TipoCliente
                cliente={cliente}
                setCliente={setCliente}
                setCajeroSeleccionado={setCajeroSeleccionado}
                cajeroSeleccionado={cajeroSeleccionado}
                tipoCliente={tipoCliente}
                setTipoCliente={setTipoCliente}
                setRefId={setRefId}
              />
            </div>
            <div className="col-sm py-1">
              <label htmlFor="exampleFormControlInput1" className=" form-label">
                Caja
              </label>
              <Select
                defaultValue={cajaSelectDef}
                value={cajaSelectDef}
                onChange={(e) => updateCaja(e)}
                options={cajaSelect}
              ></Select>
            </div>
          </Row>
          <Row>
            <div className="col py-1">
              <label htmlFor="exampleFormControlInput1" className=" form-label">
                Impresora
              </label>
              <Select
                defaultValue={impresoraSelectDef}
                value={impresoraSelectDef}
                onChange={(e) => updateImpresora(e)}
                options={impresoraSelect}
              ></Select>
            </div>
            <div className="col py-1">
              <label htmlFor="exampleFormControlInput1" className=" form-label">
                Cajero
              </label>
              <br />

              <p> {cajero?.nombre}</p>
            </div>
          </Row>
        </div>
      </div>

      <div>
        <div className="row rounded border py-2">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={6}>
              {/* <label htmlFor="exampleFormControlInput1">
                Estación
              </label> */}
              <Typography variant="subtitle1" mb={1}>
                Estación
              </Typography>
              <Select
                defaultValue={estacionSelectDef}
                value={estacionSelectDef}
                options={estacionSelect}
              ></Select>
            </Grid>
            <Grid item xs={6}>
              <Grid container rowSpacing={{ xs: 1 }}>
                <Grid item xs={9}>
                  <Typography>Cliente</Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    mt={0}
                    sx={{ paddingTop: 0 }}
                    onClick={() => setCrearCliente(true)}
                  >
                    <PersonAddIcon pt={0} fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={tipoClienteDef?.nombreCliente}
                    value={clienteSeleccionado?.nombre}
                    onClick={handleShowCliente}
                    readOnly
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="row ">
            <div className="col-sm">
              <label htmlFor="exampleFormControlInput1" className=" form-label">
                Comentarios
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Comentario"
                onChange={(e) => {
                  updateComentario(e);
                }}
              />
            </div>
          </div>
        </div>

        <div className="">
          <div className="">
            <div className="row rounded border p-2">
              <div className="col-sm py-2">
                <div className="row">
                  <div className="col-sm">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Subtot.(E.)
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.subTotalE}
                      value={"$" + resCantidad.subTotalE}
                      readOnly
                    />
                  </div>
                  <div className="col-sm ">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Desc.(E)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.descE}
                      value={"$" + resCantidad.descE}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm py-1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Subtot.(No E.)
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.subTotalNoE}
                      value={"$" + resCantidad.subTotalNoE}
                      readOnly
                    />
                  </div>
                  <div className="col-sm py-1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Desc.(No E)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.descNoE}
                      value={"$" + resCantidad.descNoE}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm py-1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Subtotal
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.subTotal}
                      value={"$" + resCantidad.subTotal}
                      readOnly
                    />
                  </div>
                  <div className="col-sm py-1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      ITBM
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.itbmCalculado}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm " style={{ background: "#f6f6f6" }}>
                <div className="row">
                  <div className="col-sm py-2">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Artículos
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={resCantidad.articulos}
                      value={resCantidad.articulos}
                      readOnly
                    />
                  </div>
                  <div className="col-sm py-2">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Total
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.total}
                      value={"$" + resCantidad.total}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm py-1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Pago
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.pago}
                      value={"$" + resCantidad.pago}
                      readOnly
                    />
                  </div>
                  <div className="col-sm py-1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className=" form-label"
                    >
                      Cambio
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"$" + resCantidad.cambio}
                      value={"$" + resCantidad.cambio}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm py-2">
                    <Button
                      className="btn btn-warning"
                      style={{ width: "100%" }}
                      size="sm"
                      onClick={() => handleRefresh()}
                    >
                      <div className="is-flex is-flex-direction-column">
                        <i className="fa fa-trash fs-4 me-2"></i> Cancelar
                      </div>
                    </Button>
                  </div>
                  <div className="col-sm py-2">
                    <Button
                      className="btn btn-success"
                      style={{ color: "white", width: "100%" }}
                      size="sm"
                      onClick={() => guardarFactura(resCantidad)}
                    >
                      {saveFactura?.facturar ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div className="is-flex is-flex-direction-column">
                          <i
                            className="fa fa-envelope fs-4 me-2"
                            style={{ color: "white" }}
                          ></i>{" "}
                          Guardar
                        </div>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facturacion;
