import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import useAuth from "./context/useAuth";

const RenovarSesion = ({ setRenovarSesion, setStart, start, onClose }) => {
  const [counter, setCounter] = useState(26);

  const { logout, refresh, setCloseAllWindows, setTestVencimiento, token } =
    useAuth();
  useEffect(() => {
    const timer =
      token && setInterval(() => setCounter((prev) => prev - 1), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    counter <= 0 && cerrarSesion();
  }, [counter]);
  const testContinuar = () => {
    console.log("testContinuar");
    setTestVencimiento(120);
    setRenovarSesion(false);
    setStart(!start);
  };
  const continuar = async () => {
    console.log("continuar");
    await refresh();
    setRenovarSesion(false);
    //Comienza el timer para el proximo final de sesion
    setStart(!start);
  };
  const cerrarSesion = () => {
    logout();
    onClose();
    window.location.reload();
  };

  return (
    <Card>
      <CardHeader title="La sesión está a punto de expirar."></CardHeader>
      <CardContent>
        <Typography variant="subtitle1">
          La sesión expirará en {counter} segundos
        </Typography>

        <Typography variant="subtitle1">¿Desea continuar?</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={continuar}>Continuar</Button>
        <Button color="error" onClick={cerrarSesion}>
          Cerrar Sesión
        </Button>
      </CardActions>
    </Card>
  );
};

export default RenovarSesion;
