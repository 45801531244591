const refrescarToken = async (
  token,
  setToken,
  setCookie,
  setRefreshToken,
  setVencimiento
) => {
  if (token) {
    //   console.log("the token", token);
    //   const url = `${process.env.REACT_APP_Url}oauth/token?refresh_token=${token}`;
    //   // let urlencoded = new URLSearchParams();
    //   // urlencoded.append("refresh_token", token);
    //   //urlencoded.append("grant_type", "refresh_token");
    //   const params = {
    //     refresh_token: token,
    //     grant_type: token
    //   }
    //   //const urlEncoded = new URLSearchParams(urlencoded);
    //   const options = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //       Authorization: process.env.REACT_APP_Authorization,
    //     },
    //     body: JSON.stringify(params),
    //     redirect: "follow"
    //   };

    //   const response = await fetch(url, options);
    //   if (!response.ok) {
    //     const { status, statusText, url } = response;
    //     throw Error(`${status}, ${statusText} in fetch ${url}`);
    //   }

    //   const data = await response.json();
    //   console.log('refresh data', data)
    //   return data;
    // }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", process.env.REACT_APP_Authorization);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "refresh_token");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(
      `https://ptb.dev.getsigmacare.com/pos/v1/oauth/token?refresh_token=${token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setCookie("token", res.access_token);
        setCookie("refresh_token", res.refresh_token);
        setCookie("vencimiento", res.expires_in);
        setToken(res.access_token);
        setVencimiento(res.expires_in);
        setRefreshToken(res.refresh_token);
      })
      .then((result) => console.log("this is res", result))
      .catch((error) => console.log("error", error));
  }
};
export default refrescarToken;
