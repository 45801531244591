import { useContext } from "react";
import { UserContext } from "./UserContext.jsx";

const useAuth = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used inside AuthContext");
  }
  return context;
};

export default useAuth;
