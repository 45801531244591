import { useReducer, useEffect } from "react";
import { registrar, eliminar } from "../ayudantes/registrarNuevoCajero";
import useAuth from "../context/useAuth";

const useRegistrarCajero = () => {
  const { token } = useAuth();
  const cajeroInicial = {
    idCajero: null,
    nombreUsuario: null,
    password: null,
  };
  const randomPassword = () => {
    return Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
  };

  let flag;
  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "registrar":
        flag = "registrar";
        return {
          idCajero: payload.idCajero,
          nombreUsuario: payload.nombreUsuario,
          password: randomPassword(),
        };
      case "eliminar":
        flag = "eliminar";
        return { idUsuarioPos: payload.idUsuarioPos };
      default:
        console.error("Ningun caso funciona con switch");
    }
  };

  const [state, dispatch] = useReducer(reducer, cajeroInicial);

  useEffect(() => {
    if (flag === "eliminar") {
      eliminar(state.idUsuarioPos, token);
    }
    if (flag === "registrar") {
      registrar(state, token);
    }
  });
  return { dispatch };
};

export default useRegistrarCajero;
