import React, { useEffect } from "react";
import Stack from "react-bootstrap/esm/Stack";
import Tipo from "./Tipo";
const Cliente = ({
  buscarCliente,
  setbuscarCliente,
  clientes,
  porCobrar,
  setClientes,
  subRefType,
  setSubRefType,
  onHide,
  setClienteSeleccionado,
}) => {
  const close = () => setTimeout(onHide, 500);
  const defineSelected = (i) => {
    const newList = clientes.map((item, index) => {
      let updatedItem = {
        ...item,
        isSelected: "",
      };

      if (index === i) {
        updatedItem = {
          ...item,
          isSelected: "selected",
        };
        setClienteSeleccionado(item);
        return updatedItem;
      }
      if (i !== index) {
        const updatedItem = {
          ...item,
          isSelected: "",
        };

        return updatedItem;
      }
    });

    setClientes(newList);
    close();
  };

  useEffect(() => {
    let mounted = true;
    mounted &&
      setbuscarCliente({
        code: "",
        idCompania: 1,
        name: "",
        refId: "1",
        referTo: "EMPL",
        ruc: "",
        subRefType: subRefType,
      });
    return () => (mounted = false);
  }, [subRefType, setbuscarCliente]);
  return (
    <div>
      <form>
        <Stack direction="horizontal" gap={3}>
          {porCobrar && (
            <Tipo setSubRefType={setSubRefType} subRefType={subRefType} />
          )}
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            Código
          </label>
          <input
            className="input "
            type="text"
            placeholder="Buscar"
            label="Buscar"
            onChange={(e) =>
              setbuscarCliente({ ...buscarCliente, code: e.target.value })
            }
          ></input>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            Nombre
          </label>
          <input
            className="input "
            type="text"
            placeholder="Buscar"
            onChange={(e) =>
              setbuscarCliente({ ...buscarCliente, name: e.target.value })
            }
          ></input>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            RUC/Cédula
          </label>
          <input
            className="input "
            type="text"
            placeholder="Buscar"
            onChange={(e) =>
              setbuscarCliente({ ...buscarCliente, ruc: e.target.value })
            }
          ></input>
        </Stack>
      </form>
      <p className="mt-4">Total Registro(s): {clientes?.length}</p>

      <table
        id="kt_datatable_example_2"
        className="table table-striped table-row-bordered gy-5 gs-7 mt-0"
      >
        <thead className="fix-top sticky-top bg-light">
          <tr className="fw-bold fs-6 text-gray-800 ">
            <th>Nombre</th>
            <th>Código</th>
            {porCobrar && (
              <>
                <td>Ruc/Cédula</td>
                <td>Límite de crédito</td>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {clientes?.map((cliente, index) => (
            <tr
              className={cliente.isSelected ? "is-selected" : ""}
              key={index}
              onClick={() => {
                defineSelected(index);
              }}
            >
              <td>{cliente?.nombre}</td>
              <td>{cliente?.codigo}</td>
              {porCobrar && (
                <>
                  <td>{cliente?.ruc}</td>
                  <td>{cliente?.montoCrLimite}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Cliente;
