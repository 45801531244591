import fetchIntercept from "fetch-intercept";
import { Navigate } from "react-router-dom";

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call

    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object

    if (response.status === 401) {
      <Navigate to="/dashboard" replace={true} />;
    }
    return response;
  },

  responseError: function (error) {
    // return Promise.reject(error);
  },
});
