import React, { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Pagination from "react-bootstrap/Pagination";
import usePaginacion from "../../hooks/usePaginacion";
import { Pagination as PaginationM } from "@mui/material";
const NotaCredito = ({
  data,
  setData,
  total,
  setBuscarParam,
  buscarParam,
  obj,
  onHide,
  setFactura,
}) => {
  const [
    paginas,
    anterior,
    siguiente,
    botonAnterior,
    botonSiguiente,
    setPaginas,
  ] = usePaginacion(obj?.totalPages);
  const [selected, setSelected] = useState("");
  const close = () => setTimeout(onHide, 500);
  const defineSelected = (i) => {
    const newList = data.map((item, index) => {
      if (index === i) {
        const updatedItem = {
          ...item,
          isSelected: "selected",
        };

        return updatedItem;
      }
      if (index !== i) {
        const updatedItem = {
          ...item,
          isSelected: "",
        };

        return updatedItem;
      }
      return item;
    });

    setData(newList);
  };
  useEffect(() => {}, [
    botonAnterior,
    botonSiguiente,
    buscarParam,
    obj,
    paginas,
    data,
  ]);
  return (
    <div>
      <form>
        <Stack direction="horizontal" gap={3}>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            No. Factura
          </label>
          <input
            className="input "
            type="text"
            placeholder="Buscar"
            label="Buscar"
            onChange={(e) =>
              setBuscarParam({ ...buscarParam, codigo: e.target.value })
            }
          ></input>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            No. Referencia
          </label>
          <input
            className="input "
            type="text"
            placeholder="Buscar"
            onChange={(e) =>
              setBuscarParam({ ...buscarParam, noReferencia: e.target.value })
            }
          ></input>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            Cliente
          </label>
          <input
            className="input "
            type="text"
            placeholder="Buscar"
            onChange={(e) =>
              setBuscarParam({
                ...buscarParam,
                paciente: e.target.value,
                page: "0",
              })
            }
          ></input>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            Desde
          </label>
          <input
            className="input "
            type="date"
            placeholder="Buscar"
            onChange={(e) =>
              setBuscarParam({
                ...buscarParam,
                fechaIni: e.target.value + "T00:00:00.000Z",
              })
            }
          ></input>
          <label htmlFor="exampleFormControlInput1" className=" form-label">
            Hasta
          </label>
          <input
            className="input "
            type="date"
            placeholder=""
            onChange={(e) =>
              setBuscarParam({
                ...buscarParam,
                fechaFin: e.target.value + "T00:00:00.000Z",
              })
            }
          ></input>
        </Stack>
      </form>
      <p>Total Registro(s): {total}</p>
      <PaginationM
        count={obj?.totalPages}
        boundaryCount={5}
        onChange={(e, p) => setBuscarParam({ ...buscarParam, page: p - 1 })}
      ></PaginationM>
      <table
        id="kt_datatable_example_2"
        className="table table-striped table-row-bordered gy-5 gs-7 mt-0"
      >
        <thead className="fix-top sticky-top bg-light">
          <tr className="fw-bold fs-6 text-gray-800 ">
            <th>Codigo DGI</th>
            <th>Codigo</th>
            <th>No. Referencia</th>
            <th>Fecha</th>
            <th>Tipo Docto.</th>
            <th>Cliente</th>
            <th>RUC</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((datos, index) => (
            <tr
              className={datos.isSelected === "selected" ? "is-selected" : ""}
              key={index}
              onClick={() => {
                setFactura(datos.printedNo);
                defineSelected(index);
                close();
              }}
            >
              <td>{datos.printedNo}</td>
              <td>{datos.codCajero}</td>
              <td>{datos.noReferencia}</td>
              <td>{datos.doctoDate}</td>
              <td>{datos.docType}</td>
              <td>{datos.clientName}</td>
              <td>{datos.ruc}</td>
              <td>{datos.netAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NotaCredito;
