import React from "react";
import useAuth from "../../context/useAuth";
import { toast } from "react-toastify";
import {
  Container,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState, useEffect } from "react";

const Descuento = ({
  articulo,
  infoArticulo,
  setInfoArticulo,
  setMensajeDescuento,
  conDescuento,
  setConDescuento,
  setArticulo,
  setMostrarDescuento,
}) => {
  const { token, logout } = useAuth();
  const [tipoCantidad, setTipoCantidad] = useState(false);
  const [radioSelected, setRadioSelected] = useState("monto");
  const [cantidad, setCantidad] = useState();
  const [valor, setValor] = useState();
  const [porcentual, setPorcentual] = useState(false);
  const [tipoDescuento, setTipoDescuento] = useState();
  const notifyNoHayDescuento = () =>
    toast.error("A ESTE ARTICULO NO SE LE PUEDE APLICAR DESCUENTO!");
  const notifyDescuentoNoValido = () =>
    toast.error("El valor de tipo de descuento no está definido!");
  const handleCantidad = (e) => {
    setCantidad(e.target.value);
  };

  const valDescAut = (optTitleColumn, optValueColumn) => {
    let tipo = optTitleColumn.split("|");

    if (tipo[1] === "") {
      notifyDescuentoNoValido();
    } else {
      let porcentaje = +tipo?.[1] / 100;
      let mensaje = "(" + tipo?.[2] + ")" + " - " + infoArticulo.descripcion;
      let result = +infoArticulo.total * +porcentaje.toFixed(2);
      let total = +result * +infoArticulo.cantidad;

      setInfoArticulo({
        ...infoArticulo,
        descuentoDesc: mensaje,
        descuentoTotal: total.toFixed(2),
        descuentoCantidad: infoArticulo.cantidad,
        descuentoUnitario: result.toFixed(2),
        tipoLetraDescuento: tipo[2],
        tipoDescuento: "P",
        idDescuento: optValueColumn ? optValueColumn : "",
      });
      setMostrarDescuento(false);
    }
  };

  const checkRadio = (e) => {
    let tipoDescuento;
    switch (e.target.value) {
      case "monto":
        tipoDescuento = "M";
        break;
      case "porcentual":
        tipoDescuento = "P";
        break;
      case "cantidad":
        tipoDescuento = "R";
        break;
      default:
        tipoDescuento = "";
    }
    setRadioSelected(tipoDescuento);
  };

  useEffect(() => {
    const getDescuento = async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await fetch(
        `${process.env.REACT_APP_Url}common/pos/descuento/${infoArticulo.codArticulo}`,
        options
      );
      if (data.status === 401) {
        logout();
      }
      const response = await data.json();
      setTipoDescuento(response);
    };
    if (
      infoArticulo.codArticulo !== undefined &&
      infoArticulo.codArticulo !== 0
    ) {
      getDescuento();
    }
  }, [infoArticulo.codArticulo, infoArticulo.cantidad]);

  useEffect(() => {}, [porcentual, tipoCantidad]);

  useEffect(() => {
    if (tipoDescuento !== undefined && tipoDescuento.length === 0) {
      notifyNoHayDescuento();
      setMostrarDescuento(false);
    }
  }, [tipoDescuento]);

  const handleClick = () => {
    // Revisar Tipo
    // Cantidad debe ser menor o igual que articulo.cantidad
    //Definir articulo
    const articuloSeleccionado = articulo.indexOf(infoArticulo);
    let result;
    if (tipoCantidad !== true && porcentual !== true) {
      alert("Seleccione un tipo de descuento");
      return;
    } else if (
      tipoCantidad === true &&
      +cantidad <= articulo[articuloSeleccionado].cantidad &&
      +valor <= articulo[articuloSeleccionado].total
    ) {
      setPorcentual(false);
      let msg = +valor;
      setMensajeDescuento(msg);
      result = +infoArticulo.total - +valor;
      setConDescuento(result.toFixed(2));
    } else if (
      porcentual === true &&
      +cantidad <= articulo[articuloSeleccionado].cantidad
    ) {
      setTipoCantidad(false);
      let porcentaje =
        +articulo[articuloSeleccionado].precio * +cantidad * (valor / 100);

      setMensajeDescuento(porcentaje.toFixed(2));
      result = +infoArticulo.total - porcentaje.toFixed(2);
      setConDescuento(result.toFixed(2));
    } else {
      alert("Verifique las cantidades");
      return;
    }
    const newArray = articulo;
    newArray[articuloSeleccionado].total = result.toFixed(2);
    setArticulo(newArray);
    alert("Se ha aplicado el descuento");
  };
  return (
    <Container>
      <Typography variant="h4" sx={{ my: 4 }} align="center">
        Descuento por artículo
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={1} justifyContent="center">
            {tipoDescuento?.map((tipoD, index) => (
              <Grid item xs={3} md={3} key={index}>
                <Button
                  onClick={() => {
                    valDescAut(tipoD.optTitleColumn, tipoD.optValueColumn);
                  }}
                  variant="contained"
                  key={index}
                >
                  {tipoD.optLabelColumn}
                </Button>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      <Card
        
        // style={{
        //   minHeight: "20rem",
        //   display: "flex",
        //   gap: "0.5rem",
        //   alignItems: "center",
        // }}
      >
        <CardContent >
          <div>
            <Typography variant="h4" align="center">
              Tipo:
            </Typography>
          </div>
          <Container >
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={9} md={9} align="center">
                <FormControl>
                  <RadioGroup row defaultValue="monto">
                    <FormControlLabel
                      id="monto"
                      name="monto"
                      onChange={() => setTipoCantidad(true)}
                      label="Monto"
                      control={
                        <Radio
                          // checked={radioSelected === "monto"}
                          onChange={(e) => checkRadio(e)}
                          value="monto"
                        />
                      }
                    />

                    <FormControlLabel
                      id="porcentual"
                      name="porcentual"
                      onChange={() => {
                        setPorcentual(true);
                      }}
                      label="Porcentual"
                      control={
                        <Radio
                          // checked={radioSelected === "porcentual"}
                          onChange={(e) => checkRadio(e)}
                          value="porcentual"
                        />
                      }
                    />
                    <FormControlLabel
                      id="cantidad"
                      name="cantidad"
                      onChange={() => setTipoCantidad(true)}
                      label="Cantidad"
                      control={
                        <Radio
                          // checked={radioSelected === "cantidad"}
                          onChange={(e) => checkRadio(e)}
                          value="cantidad"
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  label="Cantidad"
                  onChange={(e) => handleCantidad(e)}
                ></TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Valor"
                  onChange={(e) => setValor(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>
          </Container>
          <CardActions>
            <Button
              style={{ margin: "0 auto", display: "flex", marginTop: "2rem" }}
              onClick={handleClick}
            >
              Aplicar
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Descuento;
